import React, { useState, FC } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import classes from './ModalWorkplace.module.scss';
import { useGetWorkplacesList } from '../../../../hooks/MainLayout/useGetWokplacesList';
import { usePutUserAuthInfo } from '../../../../hooks/MainLayout/usePutUserAuthInfo';
import { FormatOptionLabel } from './WorkPlaceOption';

type ModalWorkplaceProps = {
  isActive: boolean;
  setActiveModal?: (value: boolean) => void;
  setWorkPlaceSelected: (value: boolean) => void;
};

export type ModalWorkplaceOptionType = {
  label: string;
  value: number;
  locked: boolean;
};

const customStyles = {
  control: (base) => ({
    ...base,
    height: 60,
    minHieght: 60,
    widht: 680,
    minWidth: 680,
    textTransform: 'uppercase',
    cursor: 'pointer'
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'white',
    color: 'black',
    textTransform: 'uppercase',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#d9ebffcc'
    }
  })
};

const ModalWorkplace: FC<ModalWorkplaceProps> = ({
  isActive,
  setActiveModal,
  setWorkPlaceSelected
}) => {
  const [selectValue, setSelectValue] = useState<ModalWorkplaceOptionType | null>(null);
  const [lockedTip, setLockedTip] = useState<null | number>(null);

  const { userWorkplacesResp } = useGetWorkplacesList();

  const { putUserAuthInfo, isLoadingPutUserAuthInfo } = usePutUserAuthInfo(
    setWorkPlaceSelected,
    setActiveModal
  );

  const handleSubmit = () => {
    if (!selectValue.locked) {
      putUserAuthInfo({ serviceUnitId: selectValue.value });
    }
  };

  return (
    <div
      className={classNames(
        classes.modal,
        { [classes.active]: isActive },
        { [classes.load]: isLoadingPutUserAuthInfo }
      )}>
      <div className={classes.modalContainer}>
        <div className={classes.title}>Выберите рабочее место</div>
        {lockedTip && (
          <div className={classes.lockedTip}>Рабочее место занято другим оператором</div>
        )}
        <div className={classes.selectContainer}>
          {userWorkplacesResp && (
            <ReactSelect
              placeholder={''}
              noOptionsMessage={() => 'Выберите рабочее место'}
              options={userWorkplacesResp}
              formatOptionLabel={(option) => (
                <FormatOptionLabel setLockedTip={setLockedTip} optionValue={option} />
              )}
              isOptionDisabled={(option) => option.locked}
              styles={customStyles}
              onChange={(value) => setSelectValue(value)}
              onMenuClose={() => setLockedTip(null)}
            />
          )}
        </div>
        <div className={classes.btnContainer}>
          <button
            className={classes.btn}
            onClick={() => handleSubmit()}
            disabled={!selectValue || isLoadingPutUserAuthInfo}>
            готово
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalWorkplace;
