import { homeRoutesURLs, mainRoutesURLs } from './routes';

export const rolesURLs = [
  {
    role: 'Оператор на въезд',
    path: `main/${mainRoutesURLs.operator1PagePath}`
  },
  {
    role: 'Оператор на выезд',
    path: `main/${mainRoutesURLs.operator2PagePath}`
  },
  {
    role: 'Агент с ограниченными правами',
    path: `main/${mainRoutesURLs.agent1PagePath}`
  },
  {
    role: 'Агент с расширенными правами',
    path: `main/${mainRoutesURLs.agent2PagePath}`
  },
  {
    role: 'Администратор',
    path: homeRoutesURLs.adminPagePath
  }
];
