import { useQuery } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';

export const useGetClientsList = (value: string) => {
  const {
    data: clientsListResp,
    isLoading: isLoadingClientsList,
    isSuccess: isClientsListRecieved
  } = useQuery(
    ['clientsList', value],
    () => axios.get(OperatorsAPI.getClientsData(value), commonHeaders()),
    {
      enabled: value.length > 2
    }
  );

  return { clientsListResp, isLoadingClientsList, isClientsListRecieved };
};
