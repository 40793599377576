import { saveAs } from 'file-saver';

// Comma delimiter (Not supported everywhere)
// export const saveAsCSV = (data, header) => {
//   const csvHeader = Object.values(header).join(',');
//   const csvRows = data.map((row) => Object.values(row).join(','));
//   const csvData = [csvHeader, ...csvRows].join('\n');
//   const blob = new Blob([String.fromCharCode(0xfeff), csvData], { type: 'text/csv;charset=utf-8' });
//   saveAs(blob, 'data.csv');
// };

// Semicolon delimiter
export const saveAsCSV = (data, header) => {
  const csvHeader = Object.values(header).join(';');
  const csvRows = data.map((row) => {
    return Object.values(row)
      .map((value) => {
        if (typeof value === 'boolean') {
          return value ? 'Да' : 'Нет';
        }
        return value;
      })
      .join(';');
  });

  const csvData = [csvHeader, ...csvRows].join('\n');
  const blob = new Blob([String.fromCharCode(0xfeff), csvData], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, 'data.csv');
};
