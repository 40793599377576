import { useQuery } from 'react-query';
import axios from 'axios';
import { MonitorQueueAPI } from '../../constants/API';

export type QueueHeadersRespType = {
  id: number;
  title: string;
  subTitle: string;
};

export const useGetQueueHeaders = (token: string | null) => {
  const {
    data: queueHeadersResp,
    isFetching: isLoadingQueueHeaders,
    isSuccess: isQueueHeadersRecieved
  } = useQuery(
    'QueueHeaders',
    () =>
      axios.get<QueueHeadersRespType>(MonitorQueueAPI.getQueueHeaders, {
        params: {
          token: token
        }
      }),
    {
      select: ({ data }) => data,
      refetchInterval: 30000,
      enabled: !!token
    }
  );

  return { queueHeadersResp, isLoadingQueueHeaders, isQueueHeadersRecieved };
};
