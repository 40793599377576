import React, { useEffect, useRef, useState } from 'react';
import classes from './ModalEditPlaylist.module.scss';
import { SubmitHandler, useForm } from 'react-hook-form';

import classNames from 'classnames';
import { CustomTextField } from '../../../CustomTextField/CustomTextField';
import { crossIcon } from '../../../../../assets/icons';
import { Button } from '../../../Button/Button';
import { SearchTextField } from '../../../SearchTextField/SearchTextField';
import { ContentType } from '../../../../../pagesAdmin/AdvertisingPage/AdvertisingPage';
import { PlaylistsType } from '../../../../../hooks/Admin/Advertising/usePutPlaylist';

type FormData = {
  searchText: string;
};

type serviceType = {
  id?: number;
  title: string;
};

type ModalAccessLevelsProps = {
  isModalEditPlaylistActive: boolean;
  setIsModalEditPlaylistActive: (value: boolean) => void;
  allSystemElements: ContentType[];
  assignedElements?: ContentType[];
  editPlaylist: (data: PlaylistsType) => void;
  isLoadingEditPlaylist: boolean;
};

export const ModalEditPlaylist = ({
  isModalEditPlaylistActive,
  setIsModalEditPlaylistActive,
  editPlaylist,
  assignedElements,
  allSystemElements,
  isLoadingEditPlaylist
}: ModalAccessLevelsProps) => {
  const [servicesArray, setServicesArray] = useState(allSystemElements);
  const modalRef = useRef<HTMLDivElement>(null);
  const [list2, setList2] = useState(assignedElements);
  const [list1, setList1] = useState(filterArray(allSystemElements, list2));

  const [searchText, setSearchText] = useState('');
  const filteredList = list1.filter((item) =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const moveItem = (item: serviceType, fromList: ContentType[], toList: ContentType[]) => {
    const index = fromList.indexOf(item);
    const updatedFromList = [...fromList.slice(0, index), ...fromList.slice(index + 1)];
    const updatedToList = [...toList, item];
    fromList === list1 ? setList1(updatedFromList) : setList2(updatedFromList);
    fromList === list1 ? setList2(updatedToList) : setList1(updatedToList);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  function filterArray(arr1: ContentType[], arr2: ContentType[]) {
    return arr1.filter((obj1) => !arr2.some((obj2) => obj1.id == obj2.id));
  }

  useEffect(() => {
    if (assignedElements && assignedElements.length) {
      const filteredSystemTopics = filterArray(allSystemElements, assignedElements);

      setList2(assignedElements);
      setList1(filteredSystemTopics);
    }
  }, [assignedElements, allSystemElements]);

  const { register, handleSubmit } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      searchText: ''
    }
  });
  const filterServices = (searchValue: string): void => {
    const filteredItems = servicesArray?.filter((item) => {
      return item.title.toLowerCase().includes(searchValue.toLowerCase());
    });
    setServicesArray(filteredItems);
  };

  const onSubmit: SubmitHandler<FormData> = async () => {
    const requestBody = {
      videos: list2.map((e: serviceType, idx) => ({ videoId: e.id, order: idx + 1 }))
    };
    editPlaylist(requestBody);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsModalEditPlaylistActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={classNames(classes.modal, { [classes.active]: isModalEditPlaylistActive })}>
      <div className={classes.unlimitedAgentContainer} ref={modalRef}>
        <form className={classes.unlimitedAgentForm}>
          {isLoadingEditPlaylist && (
            <div className={classes.load}>
              <div className={classes.loadContent}>
                <p>Cохранение...</p>
              </div>
            </div>
          )}
          <h2>Редактирование плейлиста</h2>
          <div>
            <div className={classes.addContainer}>
              <div className={classes.searchItemContainer}>
                <SearchTextField
                  {...register('searchText')}
                  name="searchText"
                  labelName={'Добавить элемент'}
                  placeholder={'Введите название'}
                  clearInput={(): void => {
                    setSearchText('');
                    filterServices('');
                  }}
                  value={searchText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e)}>
                  {filteredList.map((item) => (
                    <div
                      key={item.id}
                      className={classes.serviceItem}
                      onClick={() => moveItem(item, list1, list2)}>
                      {item.title}
                    </div>
                  ))}
                </SearchTextField>
              </div>
              <div className={classes.addedItemContainer}>
                <CustomTextField labelName={'Добавленные элементы'} disabled={true}>
                  {list2.map((item) => (
                    <div
                      key={item.id}
                      className={classes.addedServiceItem}
                      onClick={() => moveItem(item, list2, list1)}>
                      <div className={classes.crossIcon}>
                        <img src={crossIcon} alt={'cross'} />
                      </div>
                      {item.title}
                    </div>
                  ))}
                </CustomTextField>
              </div>
            </div>
            <div className={classes.buttonsContainer}>
              <div className={classes.mainBtnsContainer}>
                <Button
                  content={'отмена'}
                  btnStyle={'white'}
                  onClick={() => {
                    setIsModalEditPlaylistActive(false);
                  }}
                />
                <Button
                  content={'сохранить'}
                  btnStyle={'blue'}
                  onClick={handleSubmit(onSubmit)}
                  isLoading={isLoadingEditPlaylist}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
