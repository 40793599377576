import * as yup from 'yup';

export const rulesRuLang: RegExp = /^[А-Яа-яЁё\s,.\-/]+$/;
const rulesNumber: RegExp = /^\d+$/;

export const ModalParkingSpaceSchema = yup.object({
  inputParkingName: yup
    .string()
    .required('Необходимо заполнить поле')
    .matches(rulesRuLang, { message: 'Введите текст на русском языке', excludeEmptyString: true })
    .max(128, 'Превышен лимит символов'),
  inputCount: yup
    .string()
    .required('Необходимо заполнить поле')
    .matches(rulesNumber, {
      message: 'Введите число',
      excludeEmptyString: true
    })
    .max(4, 'Превышен лимит символов'),
  inputLetter: yup.string()
});
