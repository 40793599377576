import React, { FC, useState } from 'react';
import ParkingSpacesTable, {
  parkingSpacesTableType
} from '../../components/Admin/Tables/ParkingSpacesTable/ParkingSpacesTable';
import classes from './ParkingSlotsPage.module.scss';
import ContentHeader from '../../components/Admin/ContentHeader';
import { ModalParkingSpace } from '../../components/Admin/Modals/ModalParkingSpace/ModalParkingSpace';
import { useGetAllParkingPlaces } from '../../hooks/Admin/ParkingPlacesTable/useGetAllParkingPlaces';
import { usePutUpdateParkingPlaces } from '../../hooks/Admin/ParkingPlacesTable/usePutUpdateParkingPlaces';

const tableName = 'Места на парковке';

const ParkingSlotsPage: FC = () => {
  const [modalActive, setModalActive] = useState(false);
  const [activeRowData, setActiveRowData] = useState<parkingSpacesTableType>(null);
  const { parkingPlacesResp, isLoadingParkingPlaces, isParkingPlacesRecieved } =
    useGetAllParkingPlaces();
  const parkingSpacesRowData = isParkingPlacesRecieved ? parkingPlacesResp : [];
  const { updateParkingPlacesQuantity, isParkingPlacesQuantityLoad } =
    usePutUpdateParkingPlaces(setModalActive);

  return (
    <div className={classes.container}>
      <ContentHeader name={tableName} />
      {modalActive && (
        <ModalParkingSpace
          isActive={modalActive}
          setActive={setModalActive}
          data={activeRowData}
          updateParkingPlacesQuantity={updateParkingPlacesQuantity}
          isLoad={isParkingPlacesQuantityLoad}
        />
      )}
      <ParkingSpacesTable
        setActiveRowData={setActiveRowData}
        setModalActive={setModalActive}
        parkingSpacesRowData={parkingSpacesRowData}
        isLoadingData={isLoadingParkingPlaces}
      />
    </div>
  );
};

export default ParkingSlotsPage;
