import React, { FC, useEffect, useState } from 'react';
import classes from './CarNumberPage.module.scss';
import CarNumberTable from '../../../components/Admin/Tables/AnalyticsAndReports/CarNumberTable/CarNumberTable';
import HeaderAnalyticsTables from '../../../components/Admin/HeaderAnalyticsAndReports/HeaderAnalyticsTables';
import { currentDate } from '../../../utils/Admin/AnalyticsAndReports/formatAnalyticAndReportsDate';
import { useGetCarNumberReportPeriod } from '../../../hooks/Admin/AnalyticsAndReportsTables/CarNumberReport/useGetCarNumberReport';
import { saveAsCSV } from '../../../utils/Admin/AnalyticsAndReports/saveAsCSV';
import { CarNumberHeader } from '../../../types/enums';

const tabsList = ['Период'];
const isActiveButtonCSV = true;

const CarNumberPage: FC = () => {
  const [activeTab, setActiveTab] = useState(tabsList[0]);
  const [selectedStartDate, setSelectedStartDate] = useState(currentDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const selectedDate = {
    startDate: selectedStartDate,
    endDate: selectedEndDate
  };

  const {
    carNumberReportPeriodResp: carNumberReportData,
    refetchCarNumberReport,
    isLoadingCarNumberReportPeriod
  } = useGetCarNumberReportPeriod(selectedDate);

  const handleSaveAsCSV = () => saveAsCSV(carNumberReportData, CarNumberHeader);

  useEffect(() => {
    refetchCarNumberReport();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className={classes.container}>
      <HeaderAnalyticsTables
        tabs={tabsList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSaveAsCSV={handleSaveAsCSV}
        isActiveButtonCSV={isActiveButtonCSV}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
        name="Отчет по № автомобиля"
      />

      <CarNumberTable
        carNumberReportData={carNumberReportData}
        isLoadingData={isLoadingCarNumberReportPeriod}
      />
    </div>
  );
};

export default CarNumberPage;
