import React, { FC } from 'react';
import classes from './AgentForm.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import {
  clientsListDataType,
  containersListDataType
} from '../../../pagesMain/Operator1Page/Operator1Page';
import classNames from 'classnames';
import printIcon from '../../../assets/icons/printIcon.svg';
import { topicIdValueAddCarInQueue } from '../../../hooks/Operators/useAddCarInQueue';
import {
  FormType,
  categoryOptionsType,
  companiesOptionsType,
  containerOptionsType
} from '../../../types/Operators.types';

import { TransportCategories } from '../../../types/enums';
import { regexOperatorsFullName, regexOperatorsPhone } from '../../../constants/data';
import { DataType } from '../../../hooks/Agents/useRedirectToOperator';

type AgentForm = {
  ticketId: number;
  clientsListData: clientsListDataType[];
  containersListData: containersListDataType[];
  setContainerName: (name: string) => void;
  setClientName: (name: string) => void;
  printEntryTicket: () => void;
  setEntryTicket: (id: number | null) => void;
  entryTicket: number | null;
  isDataBaseResearchInProgress?: boolean;
  synchronizeDataBase: () => void;
  redirectToOperator: (data: DataType) => void;
  isDisabledButtonLoading?: boolean;
};

const categoryOptions = [
  {
    label: 'Контейнеровозы (K)',
    value: TransportCategories.ContainerShips
  },
  {
    label: 'Тяжеловесная площадка (Т)',
    value: TransportCategories.HeavyPlatform
  },
  {
    label: 'Склад (С)',
    value: TransportCategories.Warehouse
  }
];

const AgentForm: FC<AgentForm> = ({
  clientsListData,
  containersListData,
  printEntryTicket,
  setContainerName,
  setClientName,
  setEntryTicket,
  entryTicket,
  isDataBaseResearchInProgress,
  synchronizeDataBase,
  redirectToOperator,
  isDisabledButtonLoading
}) => {
  const defaultTicketValues = {
    categorySymbol: TransportCategories.ContainerShips,
    area: '',
    container: '',
    isImport: false,
    isExport: false,
    isPriorityContainer: false,
    fullName: '',
    vehicleNumber: '',
    company: '',
    phoneNumber: ''
  };
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
    control,
    watch
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: defaultTicketValues
  });
  const onSubmit: SubmitHandler<FormType> = (data) => {
    const {
      categorySymbol,
      isExport,
      isImport,
      isPriorityContainer,
      phoneNumber,
      fullName,
      company,
      containerSelector,
      area,
      vehicleNumber
    } = data;
    const ticketBody = {
      topicId: topicIdValueAddCarInQueue,
      categorySymbol: categorySymbol,
      entryTicket: {
        isImport,
        isExport,
        isPriorityContainer: isPriorityContainer !== undefined ? isPriorityContainer : false,
        fullName,
        vehicleNumber,
        company,
        containerSelector,
        area,
        phoneNumber
      }
    };

    redirectToOperator(ticketBody);
  };

  const resetBtnHandler = () => {
    reset();
    setEntryTicket(null);
  };

  const printEntryTicketBtnHandler = () => {
    printEntryTicket();
  };

  const refreshDataBaseBtnHandler = () => {
    synchronizeDataBase();
  };

  const containersOptions = containersListData.map((e) => {
    return { ...e, label: e.name };
  });

  const getContainerValue = (value: string, options) =>
    value ? options.find((option) => option.name === value) : '';

  const companiesOptions = clientsListData.map((e) => {
    return { ...e, label: e.name };
  });

  const getCompaniesValue = (value: string, options) =>
    value ? options.find((option) => option.name === value) : value;

  const getCategoryValue = (value: string) =>
    value ? categoryOptions.find((option) => option.value === value) : '';

  return (
    <form className={classes.permitWrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.InputsSectionWrapper}>
        <div className={classes.headerSecton}>
          <Controller
            control={control}
            name="categorySymbol"
            render={({ field: { onChange, value } }) => (
              <div className={classes.categorySelectorWrapper}>
                <ReactSelect
                  placeholder={''}
                  isDisabled={isDisabledButtonLoading}
                  classNamePrefix={'operator-select'}
                  styles={{
                    control: (styles) => {
                      return {
                        ...styles,
                        borderColor: errors.categorySymbol ? 'red' : '#EFEFEF',
                        borderWidth: '2px',
                        '&:hover': {
                          boxShadow: errors.containerSelector ? 'red' : '#EFEFEF'
                        }
                      };
                    }
                  }}
                  options={categoryOptions}
                  value={getCategoryValue(value)}
                  onChange={(newValue) => {
                    onChange((newValue as categoryOptionsType).value);
                    if (
                      (newValue as categoryOptionsType).value !== TransportCategories.ContainerShips
                    ) {
                      reset({
                        categorySymbol: watch('categorySymbol'),
                        fullName: watch('fullName'),
                        isImport: watch('isImport'),
                        isExport: watch('isExport'),
                        vehicleNumber: watch('vehicleNumber'),
                        phoneNumber: watch('phoneNumber'),
                        isPriorityContainer: false,
                        area: '',
                        company: '',
                        containerSelector: ''
                      });
                    }
                    if (
                      (newValue as categoryOptionsType).value === TransportCategories.ContainerShips
                    ) {
                      setValue('company', '');
                    }
                  }}
                />
              </div>
            )}
          />
          <h1 className={classes.topTitle}>Пропуск</h1>
          <button
            type="button"
            disabled={isDataBaseResearchInProgress || isDisabledButtonLoading}
            className={classNames(classes.formBtn, classes.blueFormBtn)}
            onClick={() => refreshDataBaseBtnHandler()}>
            ОБНОВИТЬ БАЗУ
          </button>
        </div>

        <div className={classes.checkboxSection}>
          <div className={classes.checkboxWrapper}>
            <label className={classes.operatorCheckbox}>
              <input
                type="checkbox"
                disabled={isDisabledButtonLoading}
                {...register('isImport', {
                  required: watch('isExport') === false
                })}
              />
              <span
                className={classNames(classes.checkmarkActive, {
                  [classes.checkmarkUnactive]:
                    watch('isExport') === false && (errors.isExport || errors.isImport),
                  [classes.checkmarkActive]: watch('isExport') === true
                })}></span>
            </label>
            <span className={classes.checkboxLabel}>Ввоз груза</span>
          </div>
          <div className={classes.checkboxWrapper}>
            <label className={classes.operatorCheckbox}>
              <input
                type="checkbox"
                disabled={isDisabledButtonLoading}
                {...register('isExport', {
                  required: watch('isImport') === false
                })}
              />
              <span
                className={classNames(classes.checkmarkActive, {
                  [classes.checkmarkUnactive]:
                    watch('isImport') === false && (errors.isExport || errors.isImport),
                  [classes.checkmarkActive]: watch('isImport') === true
                })}></span>
            </label>
            <span className={classes.checkboxLabel}>Вывоз груза</span>
          </div>
        </div>
        {(watch('categorySymbol') === TransportCategories.ContainerShips ? true : false) && (
          <div className={classes.containersSection}>
            <div className={classes.containerSelectorWrapper}>
              <span className={classes.containerLabel}>Контейнер</span>
              <Controller
                control={control}
                name="containerSelector"
                rules={{
                  required:
                    watch('categorySymbol') === TransportCategories.ContainerShips ? true : false
                }}
                render={({ field: { onChange, value } }) => (
                  <div className={classes.containerSelector}>
                    <ReactSelect
                      placeholder={''}
                      noOptionsMessage={() => 'Введите номер контейнера'}
                      isDisabled={
                        watch('categorySymbol') !== TransportCategories.ContainerShips ||
                        isDisabledButtonLoading
                      }
                      classNamePrefix={'operator-select'}
                      styles={{
                        control: (styles) => {
                          return {
                            ...styles,
                            borderColor: errors.containerSelector ? 'red' : '#EFEFEF',
                            borderWidth: '2px',
                            '&:hover': {
                              boxShadow: errors.containerSelector ? 'red' : '#EFEFEF'
                            }
                          };
                        }
                      }}
                      options={containersOptions}
                      onInputChange={(value) => setContainerName(value)}
                      value={getContainerValue(value, containersOptions)}
                      onChange={(newValue: containerOptionsType) => {
                        setValue('area', newValue.area);
                        setValue('company', newValue.client.name);
                        onChange(newValue.name);
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div className={classes.containerAreaWrapper}>
              <span className={classes.containerLabel}>Участок</span>
              <input
                className={classNames(classes.inputOperator, classes.inputArea, {
                  [classes.errorBorder]: errors.area
                })}
                type="text"
                disabled={isDisabledButtonLoading}
                {...register('area', {
                  disabled: watch('categorySymbol') !== TransportCategories.ContainerShips,
                  required:
                    watch('categorySymbol') === TransportCategories.ContainerShips ? true : false,
                  minLength: 1,
                  maxLength: 4
                })}
              />
            </div>
            <div className={classNames(classes.checkboxWrapper, classes.checkboxMargin)}>
              <label className={classes.operatorCheckbox}>
                <input
                  type="checkbox"
                  disabled={isDisabledButtonLoading}
                  {...register('isPriorityContainer', {
                    disabled: watch('categorySymbol') !== TransportCategories.ContainerShips
                  })}
                />
                <span
                  className={classNames({
                    [classes.checkmarkUnactive]: errors.isPriorityContainer,
                    [classes.checkmarkActive]: !errors.isPriorityContainer
                  })}></span>
              </label>
              <span className={classes.checkboxLabel}>Приоритетное (только для К)</span>
            </div>
          </div>
        )}

        <div className={classes.companiesSection}>
          <span className={classes.containerLabel}>Юридическое лицо</span>
          {watch('categorySymbol') === TransportCategories.ContainerShips && (
            <div className={classes.inputFullNameWrapper}>
              <input
                readOnly={true}
                disabled={isDisabledButtonLoading}
                className={classNames(classes.inputOperator, classes.inputFullName, {
                  [classes.errorBorder]: errors.company
                })}
                type="text"
                {...register('company')}
              />
            </div>
          )}
          {watch('categorySymbol') !== TransportCategories.ContainerShips && (
            <Controller
              control={control}
              name="company"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <div className={classes.companiesSelectorWrapper}>
                  <ReactSelect
                    placeholder={''}
                    isDisabled={isDisabledButtonLoading}
                    noOptionsMessage={() => 'Введите наименование юридического лица'}
                    classNamePrefix={'operator-select'}
                    styles={{
                      control: (styles) => {
                        return {
                          ...styles,
                          borderColor: errors.company ? 'red' : '#EFEFEF',
                          borderWidth: '2px',
                          '&:hover': {
                            boxShadow: errors.containerSelector ? 'red' : '#EFEFEF'
                          }
                        };
                      }
                    }}
                    options={companiesOptions}
                    onInputChange={(value) => setClientName(value)}
                    value={getCompaniesValue(value, companiesOptions)}
                    onChange={(newValue) => onChange((newValue as companiesOptionsType).name)}
                  />
                </div>
              )}
            />
          )}
        </div>

        <div className={classes.fullNameSection}>
          <div className={classes.inputFullNameWrapper}>
            <span className={classes.containerLabel}>ФИО</span>
            <input
              className={classNames(classes.inputOperator, classes.inputFullName, {
                [classes.errorBorder]: errors.fullName
              })}
              type="text"
              disabled={isDisabledButtonLoading}
              {...register('fullName', {
                required: true,
                min: 6,
                max: 33,
                pattern: regexOperatorsFullName
              })}
            />
          </div>
          <div className={classes.inputPhoneNumberWrapper}>
            <span className={classes.containerLabel}>Номер телефона</span>
            <input
              className={classNames(classes.inputOperatorPhone, classes.inputFullName, {
                [classes.errorBorder]: errors.phoneNumber
              })}
              type="text"
              disabled={isDisabledButtonLoading}
              {...register('phoneNumber', {
                pattern: regexOperatorsPhone,
                minLength: 7,
                maxLength: 12
              })}
            />
          </div>
        </div>

        <div className={classes.carNumberSection}>
          <div className={classes.carNumberWrapper}>
            <span className={classes.containerLabel}>Номер автомобиля</span>
            <input
              className={classNames(classes.inputOperator, classes.inputCarNumber, {
                [classes.errorBorder]: errors.vehicleNumber
              })}
              type="text"
              disabled={isDisabledButtonLoading}
              {...register('vehicleNumber', { required: true, minLength: 6, maxLength: 33 })}
            />
          </div>
        </div>

        <div className={classes.formButtonSection}>
          <button
            onClick={() => resetBtnHandler()}
            className={classNames(classes.formBtn, classes.resetFormBtn)}
            type="button"
            disabled={isDisabledButtonLoading}>
            ОЧИСТИТЬ ФОРМУ
          </button>
          <div className={classes.formButtonsRight}>
            <button
              disabled={(!isValid && isDirty) || !!entryTicket || isDisabledButtonLoading}
              className={classNames(classes.formBtn, classes.blueFormBtn)}
              type="submit">
              ДОБАВИТЬ В ОЧЕРЕДЬ
            </button>
            <button
              disabled={!entryTicket || isDisabledButtonLoading}
              onClick={() => {
                printEntryTicketBtnHandler();
              }}
              className={classNames(classes.formBtn, classes.blueFormBtn)}
              type="button">
              Печать
              <img src={printIcon} alt="printIcon" />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AgentForm;
