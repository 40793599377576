import React, { useEffect, useState } from 'react';
import {
  queueScreenArrowRightIcon,
  queueScreenTicketIcon,
  queueScreenWindowIcon
} from '../../../assets/icons';
import styles from './QueuePanel.module.scss';
import classNames from 'classnames';
import { TicketStatus } from '../../../types/enums';
import { useQueueItems } from '../../../hooks/MonitorQueue/useQueueItems';
import SoundPlayer from './SoundPlayer';
import { splitText } from '../../../utils/QueuePage/splitSting';
import { useUpdateSoundStatus } from '../../../hooks/MonitorQueue/useUpdateSoundStatus';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import ModalQueueNewTicket from '../Modals/ModalQueueNewTicket/ModalQueueNewTicket';
import WeatherForecast from './Weather/WeatherForecast';
import { isQueueTitleEnabled, isQueueWeatherForecastEnabled } from '../../../constants/API';
import { useGetQueueHeaders } from '../../../hooks/MonitorQueue/useGetQueueHeaders';
import { useGetQueueVideoPlaylist } from '../../../hooks/MonitorQueue/useGetQueueVideoPlaylist';
import { useSearchParams } from 'react-router-dom';

export type MonitorUnitType = {
  itemId: number;
  itemName: string;
  window: string;
  status: string;
  audioList: string[];
  isSoundPlayed: boolean;
};

export type MonitorsUnitType = {
  items: MonitorUnitType[];
};

export type ReceivedSoundArrType = {
  sounds: string[][];
  receivedSoundsIds: number[];
};

export const QueuePanel = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [receivedSoundArr, setReceivedSoundArr] = useState<ReceivedSoundArrType>();
  const [isSoundPlaying, setIsSoundPlaying] = useState<boolean>(false);
  const { queueItemsResp, isQueueItemsRecieved } = useQueueItems(
    token,
    setReceivedSoundArr,
    isSoundPlaying
  );
  const { queueHeadersResp } = useGetQueueHeaders(token);
  const [videoPlaylist, setVideoPlaylist] = useState<string[]>();
  const { queueVideoPlaylistResp } = useGetQueueVideoPlaylist(token, setVideoPlaylist);
  const { updateSoundStatus } = useUpdateSoundStatus();
  const [isModalActive, setIsModalActive] = useState(false);

  useEffect(() => {
    if (queueItemsResp?.items) {
      const isNewTicketsExist = queueItemsResp.items?.find(
        (e) => e.status !== TicketStatus.TakeInWorking
      );
      if (!isNewTicketsExist) {
        setIsModalActive(false);
      }
      if (isNewTicketsExist) {
        setIsModalActive(true);
      }
    }
  }, [queueItemsResp]);

  return (
    <div className={styles.queuePanelContainer}>
      {isQueueItemsRecieved && token && (
        <SoundPlayer
          token={token}
          updateSoundStatus={updateSoundStatus}
          soundsData={receivedSoundArr}
          setIsSoundPlaying={setIsSoundPlaying}
        />
      )}
      {isModalActive && queueItemsResp?.items && <ModalQueueNewTicket tickets={queueItemsResp} />}
      <div className={styles.videoContainer}>
        <div className={styles.videoBox}>
          {queueVideoPlaylistResp && <VideoPlayer videoPlaylist={videoPlaylist} />}
        </div>
      </div>
      <div
        className={classNames(styles.titleContainer, {
          [styles.isTitleActive]: isQueueTitleEnabled,
          [styles.isWeatherForecastActive]: isQueueWeatherForecastEnabled
        })}>
        {isQueueTitleEnabled && queueHeadersResp && (
          <div className={styles.title}>
            <p>{queueHeadersResp.title}</p>
            <p>{queueHeadersResp.subTitle}</p>
          </div>
        )}
        {isQueueWeatherForecastEnabled && <WeatherForecast />}
      </div>
      <div className={styles.queueContainer}>
        <div className={styles.queueContainerHeader}>
          <div className={styles.queueHeaderTitle}>
            <img src={queueScreenTicketIcon} alt="TicketIcon" />
            <span className={styles.queueHeaderText}>Талон</span>
          </div>
          <div className={styles.queueHeaderTitle}>
            <img src={queueScreenWindowIcon} alt="WindowIcon" />
            <span className={styles.queueHeaderText}>Окно</span>
          </div>
        </div>
        <div className={styles.queueContainerBody}>
          {queueItemsResp?.items?.map((item: MonitorUnitType) => (
            <div
              key={item.itemId}
              className={classNames(styles.itemQueue, {
                [styles.itemQueueBlink]: item.status !== TicketStatus.TakeInWorking
              })}>
              <div
                className={classNames(styles.ticket, {
                  [styles.ticketBlinkBG]: item.status !== TicketStatus.TakeInWorking,
                  [styles.longTicketName]: item.itemName.length > 4
                })}>
                <span>{item.itemName}</span>
              </div>
              <img src={queueScreenArrowRightIcon} alt="ArrowRightIcon" />
              <div className={styles.post}>
                {splitText(item.window).map((el, index) => {
                  return (
                    <span
                      className={classNames(styles.nowrap, {
                        [styles.sizeLarge]: el.length < 7,
                        [styles.sizeMiddle]: splitText(item.window).length === 1 && el.length > 6,
                        [styles.sizeSmall]: splitText(item.window).length > 1
                      })}
                      key={index + 1}>
                      {el}
                    </span>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QueuePanel;
