import React, { useEffect } from 'react';

import { ReceivedSoundArrType } from './QueuePanel';
import { baseSoundURL } from '../../../config';
import { UpdateSoundDataType } from '../../../hooks/MonitorQueue/useUpdateSoundStatus';

interface SoundPlayerProps {
  token: string;
  soundsData: ReceivedSoundArrType;
  updateSoundStatus: (data: UpdateSoundDataType) => void;
  setIsSoundPlaying: (status: boolean) => void;
}

function playMusicSequentially(musicArr: string[], setIsSoundPlaying: (status: boolean) => void) {
  let currentTrackIndex = 0;

  const playNextTrack = () => {
    if (currentTrackIndex >= musicArr.length) {
      setIsSoundPlaying(false);
      // console.log('Воспроизведение завершено');
      return; // Завершаем воспроизведение, если достигли конца массива
    }

    const trackPath = musicArr[currentTrackIndex];
    const audio = new Audio(`${baseSoundURL}${trackPath}`);

    // console.log('currentSoundUrl: ', `${baseSoundURL}${trackPath}`);

    audio
      .play()
      // .then(() => {
      //   console.log(`Воспроизводим трек: ${baseSoundURL}${trackPath}`);
      // })
      .catch((e) => {
        console.error(e);
      });

    audio.onended = () => {
      currentTrackIndex++; // Переходим к следующему треку
      playNextTrack(); // Рекурсивно вызываем функцию для воспроизведения следующего трека
    };
  };

  playNextTrack(); // Начинаем воспроизведение с первого трека
}

const SoundPlayer: React.FC<SoundPlayerProps> = ({
  token,
  soundsData,
  updateSoundStatus,
  setIsSoundPlaying
}) => {
  useEffect(() => {
    if (soundsData.sounds.length) {
      const playSoundsArr = [];
      soundsData.sounds.reverse().map((e, idx) => {
        playSoundsArr.push(...e);
        updateSoundStatus({ id: soundsData.receivedSoundsIds[idx], token: token });
      });
      if (playSoundsArr.length) {
        setIsSoundPlaying(true);
        playMusicSequentially(playSoundsArr, setIsSoundPlaying);
      }
    }
    // if (!soundsData.sounds.length) {
    //   console.log('no sounds array');
    // }
  }, [soundsData]);

  return null;
};

export default SoundPlayer;
