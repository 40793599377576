import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';
import { checkIcon, modifierIcon } from '../../../../assets/icons';
import classes from './WorkingPlacesTable.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { handleClickOutside } from '../../../../utils/functions/handleClickOutside';
import { UsersWorkingPlacesType } from '../../../../types/Admin/WorkingPlacesTypes';
import { WorkingPlaceUpdateType } from '../../../../hooks/Admin/WorkingPlacesTable/usePutWorkingPlaceStatus';
import { ModalDeleteUse } from '../../Modals/ModalDeleteUse/ModalDeleteUse';
import { ActionMenu } from '../../ActionMenu/ActionMenuGeneral/ActionMenu';
import { ActionTypeInEditMenu } from '../../../../types/enums';
import Skeleton from '../../Skeleton/Skeleton';
import { AssignedServicesType } from '../../../../pagesAdmin/WorkplacesPage/WorkplacesPage';

export type workingPlaceType = {
  id: number;
  workingPlace: string;
  cabinet: number;
  number: number;
  assignedEmployees: UsersWorkingPlacesType[] | [];
  assignedServices?: AssignedServicesType[];
  boardIp: string;
  isActive: boolean;
  type: string;
};

const columnSizes = {
  workingPlaceWidth: 405,
  typeWidth: 74,
  numberWidth: 72,
  assignedEmployeesWidth: 415,
  assignedServicesWidth: 415,
  boardIpWidth: 120,
  isActiveWidth: 94
};

const {
  workingPlaceWidth,
  typeWidth,
  numberWidth,
  assignedEmployeesWidth,
  assignedServicesWidth,
  boardIpWidth,
  isActiveWidth
} = columnSizes;

type WorkingPlacesTableProps = {
  setActiveRowData: (data: workingPlaceType) => void;
  handleActiveModalOnEditBtn: () => void;
  workingPlacesData: workingPlaceType[] | [];
  deleteWorkingPlace: (id: number) => void;
  updateWorkingPalceStatus: (data: WorkingPlaceUpdateType) => void;
  isDeleteWorkingPlaceLoading: boolean;
  deleteModalActive: boolean;
  setDeleteModalActive: (value: boolean) => void;
  activeRowData: workingPlaceType;
  isLoadingData: boolean;
};

const WorkingPlacesTable: FC<WorkingPlacesTableProps> = ({
  setActiveRowData,
  handleActiveModalOnEditBtn,
  workingPlacesData,
  deleteModalActive,
  setDeleteModalActive,
  isDeleteWorkingPlaceLoading,
  deleteWorkingPlace,
  updateWorkingPalceStatus,
  isLoadingData
}) => {
  const [activeRow, setActiveRow] = React.useState(null);
  const [activeRowId, setActiveRowId] = useState(null);
  const columnHelper = createColumnHelper<workingPlaceType>();

  const [openMenuId, setOpenMenuId] = useState(null);

  const menuElement = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', (event: MouseEvent) =>
      handleClickOutside(event, menuElement, setOpenMenuId)
    );
    return () => {
      document.removeEventListener('mousedown', (event: MouseEvent) =>
        handleClickOutside(event, menuElement, setOpenMenuId)
      );
    };
  }, [setOpenMenuId]);

  const handleButtonClickModal = (data) => {
    setActiveRowData(data);
    handleActiveModalOnEditBtn();
    setOpenMenuId(false);
  };

  const handleClick = (id: string) => {
    setActiveRow(id);
  };

  const handleActivateStatus = (id: number, status: string) => {
    const data = { workingPlaceId: id, workingPlaceStatus: status };
    updateWorkingPalceStatus(data);
    setOpenMenuId(null);
  };

  const handleOpenDeleteModal = (data: workingPlaceType) => {
    setActiveRowData(data);
    setActiveRowId(data.id);
    setDeleteModalActive(true);
    setOpenMenuId(null);
  };

  const workingPlacesColumns = [
    columnHelper.accessor('workingPlace', {
      header: () => <div className={classes.headerWorkingPlase}>Рабочее место</div>,
      cell: ({ getValue, row }) => (
        <div className={classes.cellWorkingPlace}>
          <div className={classes.editMenuContainer}>
            <img
              id={'imgMenu'}
              src={modifierIcon}
              alt="modifierService"
              className={classes.modifierImage}
              onClick={() => {
                if (openMenuId === row?.original?.id) {
                  setOpenMenuId(null);
                } else {
                  setOpenMenuId(row?.original?.id);
                }
              }}
            />
            {openMenuId === row?.original?.id && (
              <div ref={menuElement} className={classes.actonMenuContainer}>
                <ActionMenu
                  editOnClick={() => handleButtonClickModal(row?.original)}
                  isActivate={row.original.isActive}
                  activateDeactivateClick={() =>
                    handleActivateStatus(
                      row?.original?.id,
                      row?.original?.isActive
                        ? ActionTypeInEditMenu.Deactivate
                        : ActionTypeInEditMenu.Activate
                    )
                  }
                  deleteOnClick={() => handleOpenDeleteModal(row?.original)}
                />
              </div>
            )}
          </div>
          <div className={classes.workingPlace}>{getValue()}</div>
        </div>
      ),
      size: workingPlaceWidth
    }),
    columnHelper.accessor('cabinet', {
      cell: ({ getValue }) => <div className={classes.contentCenter}>{getValue() || ''}</div>,
      header: () => <div className={classes.contentCenter}>Кабинет</div>,
      size: typeWidth
    }),
    columnHelper.accessor('number', {
      cell: ({ getValue }) => <div className={classes.contentCenter}>{getValue() || ''}</div>,
      header: () => <div className={classes.contentCenter}>Окно</div>,
      size: numberWidth
    }),
    columnHelper.accessor('assignedEmployees', {
      cell: ({ getValue }) => (
        <div className={classes.cellEmployees}>
          {getValue() &&
            getValue().map((item, index) => (
              <span key={item.id}>
                {getValue().length > 1 && getValue().length - 1 !== index
                  ? `${item.name}, `
                  : item.name}
              </span>
            ))}
        </div>
      ),
      header: () => <div className={classes.headerEmployees}>Закрепленные сотрудники</div>,
      size: assignedEmployeesWidth
    }),
    columnHelper.accessor('assignedServices', {
      cell: ({ getValue }) => (
        <div className={classes.cellEmployees}>
          {getValue() &&
            getValue().map((item, index) => (
              <span key={item.id}>
                {getValue().length > 1 && getValue().length - 1 !== index
                  ? `${item.nameRu}, `
                  : item.nameRu}
              </span>
            ))}
        </div>
      ),
      header: () => <div className={classes.headerEmployees}>Закрепленные услуги</div>,
      size: assignedServicesWidth
    }),
    columnHelper.accessor('boardIp', {
      cell: ({ getValue }) => <div className={classes.contentCenter}>{getValue() || ''}</div>,
      header: () => <div className={classes.contentCenter}>IP табло</div>,
      size: boardIpWidth
    }),
    columnHelper.accessor('isActive', {
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.cellIsActive}>
            <img src={checkIcon} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      header: () => <div className={classes.contentCenter}>Активный</div>,
      size: isActiveWidth
    })
  ];

  const columns = workingPlacesColumns;

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : workingPlacesData || []),
    [isLoadingData, workingPlacesData]
  );

  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={classes.container}>
      {deleteModalActive && activeRowId && (
        <ModalDeleteUse
          isActive={deleteModalActive}
          setActive={setDeleteModalActive}
          isDeleteLoading={isDeleteWorkingPlaceLoading}
          deleteItem={deleteWorkingPlace}
          id={activeRowId}
        />
      )}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows?.map((row) => (
              <tr
                key={row.id}
                onClick={() => handleClick(row.id)}
                className={activeRow === row.id ? classes.activeRow : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkingPlacesTable;
