import React, { FC, useEffect, useState } from 'react';
import CurrentMomentTable from '../../../components/Admin/Tables/AnalyticsAndReports/StationTables/CurrentMomentTable';
import PeriodTable from '../../../components/Admin/Tables/AnalyticsAndReports/StationTables/PeriodTable';
import classes from './StationPage.module.scss';
import HeaderAnalyticsTables from '../../../components/Admin/HeaderAnalyticsAndReports/HeaderAnalyticsTables';
import { currentDate } from '../../../utils/Admin/AnalyticsAndReports/formatAnalyticAndReportsDate';
import { useGetStationReportPeriod } from '../../../hooks/Admin/AnalyticsAndReportsTables/StationReport/useGetStationReportPeriod';
import { useGetStationReportToday } from '../../../hooks/Admin/AnalyticsAndReportsTables/StationReport/useGetStationReportToday';
import { saveAsCSV } from '../../../utils/Admin/AnalyticsAndReports/saveAsCSV';
import { StationPeriodHeader } from '../../../types/enums';

const isActiveButtonCSV = true;
const tabsList = ['Текущий момент', 'Период'];

const StationPage: FC = () => {
  const [activeTab, setActiveTab] = useState(tabsList[0]);
  const [selectedStartDate, setSelectedStartDate] = useState(currentDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const selectedDate = {
    startDate: selectedStartDate,
    endDate: selectedEndDate
  };
  const {
    stationReportPeriodResp: stationReportPeriodData,
    refetchStationReportPeriod,
    isLoadingStationReportPeriod
  } = useGetStationReportPeriod(selectedDate);
  const { stationReportTodayResp: stationReportTodayData, isLoadingStationReportToday } =
    useGetStationReportToday();

  const handleSaveAsCSV = () => saveAsCSV(stationReportPeriodData, StationPeriodHeader);

  useEffect(() => {
    refetchStationReportPeriod();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className={classes.container}>
      <HeaderAnalyticsTables
        tabs={tabsList}
        isActiveButtonCSV={'Период' === activeTab ? isActiveButtonCSV : !isActiveButtonCSV}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSaveAsCSV={handleSaveAsCSV}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
        name="По станции"
      />
      {'Текущий момент' === activeTab && (
        <CurrentMomentTable
          stationReportTodayData={stationReportTodayData}
          isLoadingData={isLoadingStationReportToday}
        />
      )}
      {'Период' === activeTab && (
        <PeriodTable
          stationReportPeriodData={stationReportPeriodData}
          isLoadingData={isLoadingStationReportPeriod}
        />
      )}
    </div>
  );
};

export default StationPage;
