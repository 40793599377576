import { useQuery } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';

export const useGetContainersList = (value: string) => {
  const {
    data: containersListResp,
    isLoading: isLoadingContainersList,
    isSuccess: isContainersListRecieved
  } = useQuery(
    ['containersList', value],
    () => axios.get(OperatorsAPI.getContainersData(value), commonHeaders()),
    {
      enabled: value.length > 2
    }
  );

  return { containersListResp, isLoadingContainersList, isContainersListRecieved };
};
