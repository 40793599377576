import React, { FC, useEffect, useState } from 'react';
import ContentHeader from '../../components/Admin/ContentHeader';
import WorkingPlacesTable from '../../components/Admin/Tables/WorkingPlacesTable';
import classes from './WorkplacesPage.module.scss';
import { ModalWorkplace } from '../../components/Admin/Modals/ModalWorkplace/ModalWorkplace';
import { workingPlaceType } from '../../components/Admin/Tables/WorkingPlacesTable/WorkingPlacesTable';
import { useGetAllWorkingPlaces } from '../../hooks/Admin/WorkingPlacesTable/useGetAllWorkingPlaces';
import { useDeleteWorkingPlace } from '../../hooks/Admin/WorkingPlacesTable/useDeleteWorkingPlace';
import { usePutWorkingPlaceStatus } from '../../hooks/Admin/WorkingPlacesTable/usePutWorkingPlaceStatus';
import { useGetAllSystemTopics } from '../../hooks/Admin/WorkingPlacesTable/useGetAllSystemTopics';
import { ModalAccessLevels } from '../../components/Admin/Modals/ModalWorkplace/ModalAssignServices';
import { useEditWorkingPlace } from '../../hooks/Admin/WorkingPlacesTable/useEditWorkingPlace';
import { useCreateWorkingPlace } from '../../hooks/Admin/WorkingPlacesTable/useCreateWorkingPlace';

const tableName = 'Рабочие места';

export type RequestBodyType = {
  id: number;
  requestBody: {
    type: string;
    description: string;
    officeNumber?: number;
    windowNumber?: number;
    digitalDisplayIp?: string;
    users: number[] | [];
    topics: number[] | [];
  };
};

export type WorkPlaceType = {
  id?: number;
  description: string;
  windowNumber: number;
  officeNumber: number;
  type: string;
  assignedEmployees: AssignedEmployeeType[] | [];
  assignedEmployeesIds: number[] | [];
  digitalDisplayIp: string;
};

export type AssignedServicesType = {
  id: number;
  nameRu: string;
};

export type AssignedEmployeeType = {
  id: number;
  name: string;
  role: string;
};

const WorkplacesPage: FC = () => {
  const [modalActive, setModalActive] = useState(false);
  const [modalAccessLevelsActive, setModalAccessLevelsActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [activeRowData, setActiveRowData] = useState<workingPlaceType>(null);
  const [assignedServicesList, setAssignedServicesList] = useState<AssignedServicesType[]>([]);
  const [availableServicesList, setAvailableServicesList] = useState<AssignedServicesType[]>([]);
  const [agentsRequestData, setAgentsRequestData] = useState<WorkPlaceType | null>(null);
  const [backStage, setBackStage] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const { workingPlacesResp, isLoadingWorkingPlaces } = useGetAllWorkingPlaces();

  const { deleteWorkingPlace, isDeleteWorkingPlaceLoading } =
    useDeleteWorkingPlace(setDeleteModalActive);
  const { updateWorkingPalceStatus } = usePutWorkingPlaceStatus();
  const { allSystemTopicsDataResp: allSystemTopics } = useGetAllSystemTopics();
  const { editWorkingPlace, isLoadingEditWorkingPlace } = useEditWorkingPlace(
    setModalAccessLevelsActive,
    setAgentsRequestData
  );
  const { createWorkingPlace, isLoadingCreateWorkingPlace } = useCreateWorkingPlace(
    setModalAccessLevelsActive,
    setAgentsRequestData,
    setIsCreateMode
  );
  const handleActiveModalOnCreateBtn = (): void => {
    setActiveRowData(null);
    setIsCreateMode(true);
    setModalActive(true);
  };

  const handleActiveModalOnEditBtn = (): void => {
    setIsCreateMode(false);
    setModalActive(true);
  };

  useEffect(() => {
    if (activeRowData) {
      setAssignedServicesList(activeRowData.assignedServices);
      setAvailableServicesList(allSystemTopics.data);
    }
  }, [activeRowData]);

  useEffect(() => {
    if (isCreateMode) {
      setAvailableServicesList(allSystemTopics.data);
      setAssignedServicesList([]);
    }
  }, [isCreateMode]);

  return (
    <div className={classes.container}>
      <ContentHeader name={tableName} handleButtonClick={handleActiveModalOnCreateBtn} />
      {modalActive && (
        <ModalWorkplace
          isActive={modalActive}
          isCreateMode={isCreateMode}
          setIsCreateMode={setIsCreateMode}
          setActive={setModalActive}
          data={activeRowData}
          setActiveRowData={setActiveRowData}
          setAgentsRequestData={setAgentsRequestData}
          setModalAccessLevelsActive={setModalAccessLevelsActive}
          agentsRequestData={agentsRequestData}
          backStage={backStage}
          setBackStage={setBackStage}
        />
      )}
      <WorkingPlacesTable
        setActiveRowData={setActiveRowData}
        handleActiveModalOnEditBtn={handleActiveModalOnEditBtn}
        workingPlacesData={workingPlacesResp}
        deleteWorkingPlace={deleteWorkingPlace}
        updateWorkingPalceStatus={updateWorkingPalceStatus}
        isDeleteWorkingPlaceLoading={isDeleteWorkingPlaceLoading}
        deleteModalActive={deleteModalActive}
        setDeleteModalActive={setDeleteModalActive}
        activeRowData={activeRowData}
        isLoadingData={isLoadingWorkingPlaces}
      />
      {allSystemTopics && modalAccessLevelsActive && (
        <ModalAccessLevels
          isActive={modalAccessLevelsActive}
          setActive={setModalAccessLevelsActive}
          assignedServices={assignedServicesList}
          setAssignedServices={setAssignedServicesList}
          availableServices={availableServicesList}
          setAvailableServices={setAvailableServicesList}
          setActiveRowData={setActiveRowData}
          allSystemTopics={allSystemTopics.data}
          setBackStage={setBackStage}
          setAgentsRequestData={setAgentsRequestData}
          isCreateMode={isCreateMode}
          setIsCreateMode={setIsCreateMode}
          setModalActive={setModalActive}
          createWorkingPlace={createWorkingPlace}
          editWorkingPlace={editWorkingPlace}
          isLoadingCreateWorkingPlace={isLoadingCreateWorkingPlace}
          isLoadingEditWorkingPlace={isLoadingEditWorkingPlace}
          agentsRequestData={agentsRequestData}
        />
      )}
    </div>
  );
};

export default WorkplacesPage;
