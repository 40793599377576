export const homeRoutesURLs = {
  mainPagePath: 'main/*',
  loginPagePath: '/',
  adminPagePath: 'admin/*',
  terminalPagePath: 'terminal',
  queuePagePath: 'queue'
};

export const mainRoutesURLs = {
  operator1PagePath: 'operator1',
  operator2PagePath: 'operator2',
  agent1PagePath: 'agent1',
  agent2PagePath: 'agent2'
};

export const adminRoutesURLs = {
  sectionServices: 'section-services',
  workplaces: 'workplaces',
  employers: 'employers',
  parkingSlots: 'parking-slots',
  analyticsAndReports: {
    analyticsAndReportsBase: 'analytics-and-reports',
    currentServicesStatus: 'current-services-status',
    station: 'station',
    company: 'company',
    carNumber: 'car-number',
    containerNumber: 'container-number',
    services: 'services',
    employersReports: 'employerrs-reports',
    visitors: 'visitors'
  },
  advertising: 'advertising',
  userArea: 'user-area'
};
