import React, { FC, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { homeRoutesURLs } from '../../constants/routes';
import LoginPage from '../../pagesMain/LoginPage';
import Page404 from '../../pagesAdmin/Page404/Page404';
import AdminLayout from '../../pagesAdmin/Layout';
import MainLayout from '../../pagesMain/Layout/MainLayout';
import TerminalPage from '../../pagesMain/TerminalPage/TerminalPage';
import QueuePage from '../../pagesMain/QueuePage/QueuePage';
import { AlertContext } from '../../context/AlertProvider';
import Alert from '../Alert/Alert';
import AxiosInterceptor from '../Interceptor/AxiosInterceptor';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';

const HomeRoutes: FC = () => {
  const { loginPagePath, mainPagePath, adminPagePath, terminalPagePath, queuePagePath } =
    homeRoutesURLs;

  const [alert, setAlert] = useState(null);
  const isLogin = localStorage.getItem('user') !== null ? true : false;
  const [isAuthenticated, setIsAuthenticated] = useState(isLogin);

  return (
    <AlertContext.Provider value={{ setAlert }}>
      <>
        {alert && (
          <Alert
            message={alert.message || alert}
            autoHideDuration={2500}
            onClose={() => setAlert(null)}
            type={alert.type}
          />
        )}

        <AxiosInterceptor setIsAuthenticated={setIsAuthenticated} />

        <Routes>
          <Route
            path={loginPagePath}
            element={
              <LoginPage
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route
              path={mainPagePath}
              element={<MainLayout setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path={adminPagePath}
              element={<AdminLayout setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path={terminalPagePath} element={<TerminalPage />} />
          <Route path={queuePagePath} element={<QueuePage />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </>
    </AlertContext.Provider>
  );
};

export default HomeRoutes;
