import { ParkingPlacesResponseDataType } from '../../../types/Admin/ParkingPlaces.types';

export const modifyParkingPlacesData = (arr: ParkingPlacesResponseDataType[]) =>
  arr.map((e) => {
    return {
      id: e.id,
      nomination: e.name,
      letter: e.symbol,
      quantity: e.quantity,
      isActive: true
    };
  });
