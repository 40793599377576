import React, { FC, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import classes from './VideoPlayer.module.scss';
import { queueScreenPlayBtnIcon } from '../../../../assets/icons';
import classNames from 'classnames';
import { isQueueVideoAutoplayEnabled } from '../../../../constants/API';

type VideoPlayerProps = {
  videoPlaylist: string[];
};

const VideoPlayer: FC<VideoPlayerProps> = ({ videoPlaylist }) => {
  const [isPlaying, setIsPlaying] = useState(isQueueVideoAutoplayEnabled);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    if (currentVideoIndex === videoPlaylist.length) {
      setCurrentVideoIndex(0);
    }
    if (currentVideoIndex) {
      setIsPlaying(true);
    }
  }, [currentVideoIndex, videoPlaylist]);

  const handlePlayNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => prevIndex + 1);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  return (
    <div className={classes.videoPlayerWrapper}>
      <ReactPlayer
        playing={isPlaying}
        volume={0}
        url={videoPlaylist[currentVideoIndex]}
        onEnded={handlePlayNextVideo}
        controls={isPlaying ? true : false}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        width="100%"
        height="100%"
      />

      <button
        onClick={handlePlayPause}
        className={classNames(classes.playBtn, {
          [classes.hideBtn]: isPlaying
        })}>
        <span className={classNames(classes.wave, classes.wave1)}></span>
        <span className={classNames(classes.wave, classes.wave2)}></span>
        <span className={classNames(classes.wave, classes.wave3)}></span>
        <img className={classes.imgIcon} src={queueScreenPlayBtnIcon} alt="PlayBtnIcon" />
      </button>
    </div>
  );
};

export default VideoPlayer;
