import { eyeGlassesIcon, tehnoSkyTerminalLogo } from '../../../../../assets/icons';
import classes from './TerminalHeader.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { languageType } from '../NormalMode/NormalMode';
import { format } from 'date-fns';
import { be, ru } from 'date-fns/locale';
import classNames from 'classnames';
import { TerminalMode } from '../../../../../pagesMain/TerminalPage/TerminalPage';
import { Languages } from '../../../../../types/enums';

type NormalHeaderType = {
  setMode: (mode: TerminalMode) => void;
  language: languageType;
  setLanguage: (newLanguage: languageType) => void;
};
const localeArray = {
  en: undefined,
  by: be,
  ru: ru
};

const NormalHeader: FC<NormalHeaderType> = ({ setMode, language, setLanguage }) => {
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setTime(format(now, 'HH:mm'));
      setDate(format(now, 'd MMMM', { locale: localeArray[language] }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [language]);
  const handleChangeLanguage = (language: languageType): void => {
    setLanguage(language);
    setDate(format(new Date(), 'd MMMM', { locale: localeArray[language] }));
  };
  const handleClickMode = () => {
    setMode(TerminalMode.NotNormal);
  };

  return (
    <header className={classes.normalHeaderContainer}>
      <div className={classes.logoDateContainer}>
        <img src={tehnoSkyTerminalLogo} alt="logo" />
        <div className={classes.date}>
          <span>{date}</span>
        </div>
        <div className={classes.time}>
          <span>{time}</span>
        </div>
      </div>
      <div className={classes.btnWrapper}>
        <button className={classes.poorVisionIcon} onClick={() => handleClickMode()}>
          <img src={eyeGlassesIcon} alt="poorVisionIcon" />
        </button>
        <button
          className={classNames(classes.languageBtn, {
            [classes.active]: language === Languages.Ru,
            ' ': language !== Languages.Ru
          })}
          onClick={() => handleChangeLanguage('ru')}>
          РУС
        </button>
        <button
          className={classNames(classes.languageBtn, {
            [classes.active]: language === Languages.By,
            ' ': language !== Languages.By
          })}
          onClick={() => handleChangeLanguage('by')}>
          БЕЛ
        </button>
        <button
          className={classNames(classes.languageBtn, {
            [classes.active]: language === Languages.En,
            ' ': language !== Languages.En
          })}
          onClick={() => handleChangeLanguage('en')}>
          EN
        </button>
      </div>
    </header>
  );
};

export default NormalHeader;
