export function printPDF(base64Image: string) {
  const byteCharacters = atob(base64Image);
  const byteNumbers: number[] = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers.push(byteCharacters.charCodeAt(i));
  }
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: 'image/png' });
  const url = URL.createObjectURL(blob);

  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = url;
  document.body.appendChild(iframe);

  const iframeContent = setTimeout(() => {
    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();
    URL.revokeObjectURL(url);
    document.body.removeChild(iframe);
  }, 1000);

  return () => clearTimeout(iframeContent);
}
