import React, { FC, useEffect, useState } from 'react';
import CompanyPeriodTable from '../../../components/Admin/Tables/AnalyticsAndReports/CompaniesTable/CompanyPeriodTable';
import CompanyDayTable from '../../../components/Admin/Tables/AnalyticsAndReports/CompaniesTable/CompanyDayTable';
import classes from './CompanyPage.module.scss';
import HeaderAnalyticsTables from '../../../components/Admin/HeaderAnalyticsAndReports/HeaderAnalyticsTables';
import { useGetCompaniesReportPeriod } from '../../../hooks/Admin/AnalyticsAndReportsTables/CompaniesReport/useGetCompaniesReportPeriod';
import { currentDate } from '../../../utils/Admin/AnalyticsAndReports/formatAnalyticAndReportsDate';
import { useGetCompaniesReportToday } from '../../../hooks/Admin/AnalyticsAndReportsTables/CompaniesReport/useGetCompaniesReportToday';
import { saveAsCSV } from '../../../utils/Admin/AnalyticsAndReports/saveAsCSV';
import { CompanyPeriodHeader, CompanyTodaysHeader } from '../../../types/enums';

const isActiveButtonCSV = true;
const tabsList = ['День', 'Период'];

const CompanyPage: FC = () => {
  const [activeTab, setActiveTab] = useState(tabsList[0]);
  const [selectedStartDate, setSelectedStartDate] = useState(currentDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const selectedDate = {
    startDate: selectedStartDate,
    endDate: selectedEndDate
  };
  const { companiesReportTodayResp: companiesReportTodayData, isLoadingCompaniesReportToday } =
    useGetCompaniesReportToday();
  const {
    companiesReportPeriodResp: companiesReportPeriodData,
    refetchCompaniesReportPeriod,
    isLoadingCompaniesReportPeriod
  } = useGetCompaniesReportPeriod(selectedDate);

  const handleSaveAsCSVToday = () => saveAsCSV(companiesReportTodayData, CompanyTodaysHeader);
  const handleSaveAsCSVPeriod = () => saveAsCSV(companiesReportPeriodData, CompanyPeriodHeader);

  useEffect(() => {
    refetchCompaniesReportPeriod();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className={classes.container}>
      <HeaderAnalyticsTables
        tabs={tabsList}
        isActiveButtonCSV={isActiveButtonCSV}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSaveAsCSV={activeTab === 'День' ? handleSaveAsCSVToday : handleSaveAsCSVPeriod}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
        name="По юридическим лицам"
      />
      {'День' === activeTab && (
        <CompanyDayTable
          companiesReportTodayData={companiesReportTodayData}
          isLoadingData={isLoadingCompaniesReportToday}
        />
      )}
      {'Период' === activeTab && (
        <CompanyPeriodTable
          companiesReportPeriodData={companiesReportPeriodData}
          isLoadingData={isLoadingCompaniesReportPeriod}
        />
      )}
    </div>
  );
};

export default CompanyPage;
