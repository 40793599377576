import { useMutation } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios from 'axios';
import { ticketDataType } from '../../pagesMain/Operator1Page/Operator1Page';
import { commonHeaders } from '../../constants/HeadersAPI';
import { queryClient } from '../../queryClient';
import { useContext } from 'react';
import { AlertContext } from '../../context/AlertProvider';
import { defaultSuccessMessage } from '../../constants/alertText';
import { AlertType } from '../../types/enums';

export const topicIdValueAddCarInQueue = 1;

export const useAddCarInQueue = (
  setPrintTicketId: (id: number | null) => void,
  setIsCalledInQueue: (status: boolean) => void
) => {
  const context = useContext(AlertContext);
  const {
    mutate: addCarInQueue,
    isLoading,
    isError
  } = useMutation(
    ['addCarInQueue'],
    (data: ticketDataType) => axios.post(OperatorsAPI.addCarInQueue(), data, commonHeaders()),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('operatorQueue');
        setPrintTicketId(res.data.entryTicketId);
        setIsCalledInQueue(true);
        context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage });
      }
    }
  );

  return { addCarInQueue, isLoading, isError };
};
