import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAPI } from '../../../constants/API';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AssignedServicesType } from '../../../pagesAdmin/EmployersPage/EmployersPage';

export const useGetAllSystemTopics = () => {
  const {
    data: allSystemTopicsDataResp,
    isFetched: isLoadingAllSystemTopics,
    isSuccess: isAllSystemTopicsRecieved
  } = useQuery(
    ['allSystemTopics'],
    () => axios.get<AssignedServicesType[]>(AdminAPI.getAllSystemTopics, commonHeaders()),
    {}
  );

  return { allSystemTopicsDataResp, isLoadingAllSystemTopics, isAllSystemTopicsRecieved };
};
