import React, { FC, useEffect, useState } from 'react';
import classes from './Operator2Page.module.scss';
import OperatorQueue from '../../components/Main/OperatorQueue/OperatorQueue';
import { useGetOperatorQueue } from '../../hooks/Operators/useGetOperatorQueue';
import { useGetCarData } from '../../hooks/Operators/useGetCarData';
import { useGetContainersList } from '../../hooks/Operators/useGetContainersList';
import { useGetClientsList } from '../../hooks/Operators/useGetClientsList';
import { useUpdateCarStatus } from '../../hooks/Operators/useUpdateCarStatus';
import { useRedirectCarInQueueOut } from '../../hooks/Operators/useRedirectCarInQueueOut';
import { OperatorQueueType } from '../../types/OperatorTypes/OperatorTypes';
import PermitOut from '../../components/Main/PermitOut/PermitOut';
import {
  clientsListDataType,
  containersListDataType,
  ticketDataType
} from '../Operator1Page/Operator1Page';
import ModalTicketInWorking from '../../components/Main/Modals/ModalTicketInWorking/ModalTicketInWorking';

const defaultTicketValues = {
  topicId: 2,
  categorySymbol: '',
  entryTicket: {
    area: '',
    container: '',
    isImport: false,
    isExport: false,
    isPriorityContainer: false,
    fullName: '',
    vehicleNumber: '',
    company: '',
    phoneNumber: ''
  }
};

type Operator2PageProps = {
  isWorkPlaceSelected: boolean;
};

const Operator2Page: FC<Operator2PageProps> = ({ isWorkPlaceSelected }) => {
  const [carCountOut, setCarCountOut] = useState(null);
  const [isActiveModalInWorking, setActiveModalInWorking] = useState<boolean>(false);

  // QUEUE RENDER
  const [operatorQueueData, setOperatorQueueData] = useState<OperatorQueueType>();
  const { operatorQueueResp, isLoadingOperatorQueue } = useGetOperatorQueue(
    isWorkPlaceSelected,
    setOperatorQueueData
  );

  // SELECTED CAR
  const [activeCarId, setActiveCarId] = useState<number | null>(null);
  const [activeCarStatus, setActiveCarStatus] = useState('');
  const selectedCarId = activeCarId;
  const { carDataResp, isCarDataRecieved } = useGetCarData(selectedCarId);
  const activeCarData = (
    isCarDataRecieved ? carDataResp.data : defaultTicketValues
  ) as ticketDataType;

  // CONTAINERS SEARCH
  const [containerName, setContainerName] = useState('');
  const { containersListResp, isContainersListRecieved } = useGetContainersList(containerName);
  const containersList = (isContainersListRecieved ? containersListResp.data.containers : []) as
    | containersListDataType[]
    | [];
  // COMPANIES SEARCH
  const [clientName, setClientName] = useState('');
  const { clientsListResp, isClientsListRecieved } = useGetClientsList(clientName);
  const clientsList = (isClientsListRecieved ? clientsListResp.data.clients : []) as
    | clientsListDataType[]
    | [];

  // CONTROL PANEL
  const { updateCarStatus } = useUpdateCarStatus(setActiveModalInWorking);
  const { redirectCarInQueueOut } = useRedirectCarInQueueOut();
  const [isSelectionFromQueue, setSelectionFromQueue] = useState(true);

  useEffect(() => {
    if (operatorQueueResp) {
      setCarCountOut({
        С: operatorQueueResp.data.parkingSpaces?.freeInCategoryC,
        К: operatorQueueResp.data.parkingSpaces?.freeInCategoryK,
        Т: operatorQueueResp.data.parkingSpaces?.freeInCategoryT
      });
    }
  }, [operatorQueueResp]);

  if (isLoadingOperatorQueue) return <div>Loading...</div>;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumnContainer}></div>
      <PermitOut
        clientsListData={clientsList}
        containersListData={containersList}
        ticketData={activeCarData}
        activeCarStatus={activeCarStatus}
        selectedCarId={selectedCarId}
        setContainerName={setContainerName}
        setClientName={setClientName}
        updateCarStatus={updateCarStatus}
        redirectCarInQueueOut={redirectCarInQueueOut}
        setActiveCarStatus={setActiveCarStatus}
        setActiveCarId={setActiveCarId}
        setSelectionFromQueue={setSelectionFromQueue}
      />
      <OperatorQueue
        queueData={operatorQueueData?.out.items}
        queueName={'Машины на территории'}
        activeCarId={activeCarId}
        setActiveCarId={setActiveCarId}
        setActiveCarStatus={setActiveCarStatus}
        isSelectionFromQueue={isSelectionFromQueue}
        parkingSpaces={operatorQueueData?.parkingSpaces}
        carsInQueue={carCountOut}
      />

      <ModalTicketInWorking isActive={isActiveModalInWorking} setActive={setActiveModalInWorking} />
    </div>
  );
};

export default Operator2Page;
