import { useQuery } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { OperatorQueueType } from '../../types/OperatorTypes/OperatorTypes';

export const useGetOperatorQueue = (
  isWorkPlaceSelected: boolean,
  setOperatorQueueData?: (queue: OperatorQueueType) => void
) => {
  const {
    data: operatorQueueResp,
    isLoading: isLoadingOperatorQueue,
    isSuccess: isOperatorQueueRecieved
  } = useQuery('operatorQueue', () => axios.get(OperatorsAPI.getQueueIn, commonHeaders()), {
    onSuccess: (resp) => {
      if (resp) {
        setOperatorQueueData(resp.data);
      }
    },
    enabled: isWorkPlaceSelected,
    refetchInterval: 4000
  });

  return { operatorQueueResp, isLoadingOperatorQueue, isOperatorQueueRecieved };
};
