import React, { FC } from 'react';
import { format } from 'date-fns';

import classes from './Carlist.module.scss';

type carlListProps = {
  carList:
    | {
        id: number;
        name: string;
        dateTime: string;
        status: string;
        categoryAuto: string;
      }[]
    | [];
  activeCarId: number;
  isSelectionFromQueue: boolean;
  handleClickCar: (id: number) => void;
  setActiveCarStatus: (status: string) => void;
  isSelectedQueueIn?: boolean;
  setIsSelectedQueueOut: (status: boolean) => void;
};

const CarList: FC<carlListProps> = ({
  carList,
  activeCarId,
  isSelectionFromQueue,
  isSelectedQueueIn,
  setIsSelectedQueueOut,
  handleClickCar,
  setActiveCarStatus
}) => {
  return (
    <div>
      {carList &&
        carList.map(({ id, dateTime, categoryAuto, name, status }) => {
          return (
            <div
              key={id}
              className={`
                ${activeCarId === id ? classes.activeCar : ''}
                ${status === 'Пропущен' ? classes.skippedCar : ''}
                ${isSelectionFromQueue ? classes.cursorEnabled : ''} 
                ${classes.carItem}
               `}
              onClick={() => {
                if (isSelectedQueueIn) {
                  setIsSelectedQueueOut(false);
                }
                if (!isSelectedQueueIn && setIsSelectedQueueOut) {
                  setIsSelectedQueueOut(true);
                }
                if (isSelectionFromQueue) {
                  setActiveCarStatus(status);
                  handleClickCar(id);
                } else {
                  return;
                }
              }}>
              <span className={classes.category}>{categoryAuto}</span>
              <span className={classes.date}>{format(dateTime, 'HH:mm')}</span>
              <span className={classes.name}>{name}</span>
            </div>
          );
        })}
    </div>
  );
};

export default CarList;
