import React, { FC, useContext, useEffect, useState } from 'react';
import Permit from '../../components/Main/Permit/Permit';
import classes from './Operator1Page.module.scss';
import OperatorQueue, { QueueItemType } from '../../components/Main/OperatorQueue/OperatorQueue';
import { useGetOperatorQueue } from '../../hooks/Operators/useGetOperatorQueue';
import { useGetCarData } from '../../hooks/Operators/useGetCarData';
import { useGetContainersList } from '../../hooks/Operators/useGetContainersList';
import { useGetClientsList } from '../../hooks/Operators/useGetClientsList';
import { useUpdateCarStatus } from '../../hooks/Operators/useUpdateCarStatus';
import { useRedirectCarInQueueOut } from '../../hooks/Operators/useRedirectCarInQueueOut';
import { useAddCarInQueue } from '../../hooks/Operators/useAddCarInQueue';
import { usePrintEntryTicket } from '../../hooks/Operators/usePrintEntryTicket';
import { AlertType, NoEmptyParkingSlotsMessage, TransportCategories } from '../../types/enums';
import { OperatorQueueType } from '../../types/OperatorTypes/OperatorTypes';
import filterCategory from '../../utils/functions/filterCategory';
import { usePostSynchronizeDataBase } from '../../hooks/Operators/usePostSynchronizeDataBase';
import { useGetRefreshEntryTicket } from '../../hooks/Operators/useGetRefreshTicket';
import ModalTicketInWorking from '../../components/Main/Modals/ModalTicketInWorking/ModalTicketInWorking';
import { AlertContext } from '../../context/AlertProvider';
import ModalLoader from '../../components/Main/Modals/ModalLoader/ModalLoader';

export type clientsListDataType = {
  code: string;
  name: string;
};

export type containersListDataType = {
  name: string;
  area: string;
  client: {
    code: string;
    name: string;
  };
};

export type ticketDataType = {
  topicId?: number;
  itemId?: number;
  categorySymbol: string;
  entryTicket: {
    entryTicketId?: number;
    isImport: boolean;
    isExport: boolean;
    fullName: string;
    vehicleNumber: string;
    company: string;
    phoneNumber: string;
    container?: string;
    area?: string | number;
    isPriorityContainer?: boolean;
  };
};

export type carInQueueType = {
  categoryAuto:
    | TransportCategories.HeavyPlatform
    | TransportCategories.Warehouse
    | TransportCategories.ContainerShips;
  dateTime: string;
  id: number;
  name: string;
  status: string;
};

const defaultTicketValues = {
  topicId: 2,
  categorySymbol: '',
  entryTicket: {
    area: '',
    container: '',
    isImport: false,
    isExport: false,
    isPriorityContainer: false,
    fullName: '',
    vehicleNumber: '',
    company: '',
    phoneNumber: ''
  }
};

type Operator1PageProps = {
  isWorkPlaceSelected: boolean;
};

const Operator1Page: FC<Operator1PageProps> = ({ isWorkPlaceSelected }) => {
  const context = useContext(AlertContext);
  const [isActiveModalInWorking, setActiveModalInWorking] = useState<boolean>(false);
  const [isDisabledButtonLoading, setIsDisabledButtonLoading] = useState(false);

  // QUEUE RENDER
  const [operatorQueueData, setOperatorQueueData] = useState<OperatorQueueType>();
  const isSelectedQueueIn = true;
  const [isSelectedQueueOut, setIsSelectedQueueOut] = useState(false);
  const [selectedQueueCategory, setSelectedQueueCategory] = useState('');
  const [selectedCarsQueue, setSelectedCarsQueue] = useState<QueueItemType[] | []>([]);
  const { isLoadingOperatorQueue } = useGetOperatorQueue(isWorkPlaceSelected, setOperatorQueueData);

  // PRINT TICKET ID
  const [printTicketId, setPrintTicketId] = useState<number | null>(null);

  // SELECTED CAR
  const [isCalledClient, setIsCalledClient] = useState(false);
  const [activeCarId, setActiveCarId] = useState<number | null>(null);
  const [activeCarStatus, setActiveCarStatus] = useState('');
  const { entryTicketResp } = useGetRefreshEntryTicket(
    setActiveCarId,
    setIsCalledClient,
    isWorkPlaceSelected,
    setPrintTicketId
  );
  const [selectedCarId, setSelectedCarId] = useState<number | null>(null);

  const { carDataResp, isCarDataRecieved } = useGetCarData(selectedCarId, setPrintTicketId);
  const activeCarData = (
    isCarDataRecieved ? carDataResp.data : defaultTicketValues
  ) as ticketDataType;
  const [isCalledInQueue, setIsCalledInQueue] = useState(false);

  // DATABASE SYNCHRONIZATION
  const { synchronizeDataBase, isLoadingDataBaseSync } = usePostSynchronizeDataBase();
  const [isDataBaseResearchInProgress, setIsDataBaseResearchInProgress] = useState(false);

  // CONTAINERS SEARCH
  const [isContainerOptionsSelected, setIsContainerOptionsSelected] = useState(true);
  const [containerName, setContainerName] = useState('');
  const { containersListResp, isContainersListRecieved } = useGetContainersList(containerName);
  const containersList = (isContainersListRecieved ? containersListResp.data.containers : []) as
    | containersListDataType[]
    | [];

  // COMPANIES SEARCH
  const [clientName, setClientName] = useState('');
  const { clientsListResp, isClientsListRecieved } = useGetClientsList(clientName);
  const clientsList = (isClientsListRecieved ? clientsListResp.data.clients : []) as
    | clientsListDataType[]
    | [];

  // CONTROL PANEL
  const { updateCarStatus } = useUpdateCarStatus(setActiveModalInWorking);
  const { redirectCarInQueueOut } = useRedirectCarInQueueOut();
  const { addCarInQueue } = useAddCarInQueue(setPrintTicketId, setIsCalledInQueue);
  const { printEntryTicket, isLoadingPrintEntryTicket } = usePrintEntryTicket(printTicketId);
  const [isSelectionFromQueue, setSelectionFromQueue] = useState(true);
  const [carCountIn, setCarCountin] = useState(null);
  const [carCountOut, setCarCountOut] = useState(null);

  // FREE PARKING SPACES CHECKOUT
  const [isParkingSpacesAvailable, setIsParkingSpacesAvailable] = useState(true);

  useEffect(() => {
    setSelectedCarId(activeCarId);
  }, [activeCarId]);

  useEffect(() => {
    if (operatorQueueData) {
      setCarCountin({
        С: filterCategory('С', operatorQueueData.in?.items).length,
        К: filterCategory('К', operatorQueueData.in?.items).length,
        Т: filterCategory('Т', operatorQueueData.in?.items).length,
        '': filterCategory('', operatorQueueData.in?.items).length
      });
      setCarCountOut({
        С: operatorQueueData.parkingSpaces?.freeInCategoryC,
        К: operatorQueueData.parkingSpaces?.freeInCategoryK,
        Т: operatorQueueData.parkingSpaces?.freeInCategoryT
      });
    }
  }, [operatorQueueData]);

  useEffect(() => {
    if (isLoadingDataBaseSync) {
      setIsDataBaseResearchInProgress(true);
    } else {
      setIsDataBaseResearchInProgress(false);
    }
  }, [isLoadingDataBaseSync]);

  useEffect(() => {
    if (operatorQueueData && isSelectedQueueIn && !isSelectedQueueOut) {
      const { isFreeInCategoryC, isFreeInCategoryK, isFreeInCategoryT } =
        operatorQueueData.parkingSpaces;
      const availableParking = {
        ['К']: isFreeInCategoryK,
        ['С']: isFreeInCategoryC,
        ['Т']: isFreeInCategoryT
      };

      if (selectedQueueCategory) {
        setIsParkingSpacesAvailable(availableParking[selectedQueueCategory]);
      }

      if (!selectedQueueCategory && selectedCarId && isCarDataRecieved && entryTicketResp) {
        setIsParkingSpacesAvailable(availableParking[activeCarData.categorySymbol]);
      }

      if (!selectedQueueCategory && !selectedCarId) {
        setIsParkingSpacesAvailable(false);
      }

      if (selectedQueueCategory && !availableParking[selectedQueueCategory]) {
        context?.setAlert({
          type: AlertType.Error,
          message: NoEmptyParkingSlotsMessage.Message
        });
      }
    }
  }, [
    operatorQueueData,
    selectedQueueCategory,
    activeCarData,
    selectedCarId,
    entryTicketResp,
    isSelectedQueueIn,
    isSelectedQueueOut,
    isParkingSpacesAvailable
  ]);

  useEffect(() => {
    if (isLoadingDataBaseSync || isLoadingPrintEntryTicket) {
      setIsDisabledButtonLoading(true);
    } else {
      setIsDisabledButtonLoading(false);
    }
  }, [isLoadingDataBaseSync, isLoadingPrintEntryTicket]);

  if (isLoadingOperatorQueue) return <div>Loading...</div>;

  return (
    <div className={classes.container}>
      <OperatorQueue
        setSelectedQueueCategory={setSelectedQueueCategory}
        isSelectedQueueIn={isSelectedQueueIn}
        setIsSelectedQueueOut={setIsSelectedQueueOut}
        setSelectedCarsQueue={setSelectedCarsQueue}
        parkingSpaces={operatorQueueData?.parkingSpaces}
        queueData={operatorQueueData?.in?.items}
        queueName={'Машины в очереди'}
        activeCarId={activeCarId}
        setActiveCarId={setActiveCarId}
        setActiveCarStatus={setActiveCarStatus}
        isSelectionFromQueue={isSelectionFromQueue}
        carsInQueue={carCountIn}
        isDisabledButtonLoading={isDisabledButtonLoading}
      />

      <Permit
        setSelectedCarId={setSelectedCarId}
        clientsListData={clientsList}
        containersListData={containersList}
        ticketData={activeCarData}
        activeCarStatus={activeCarStatus}
        selectedCarId={selectedCarId}
        printTicketId={printTicketId}
        isCalledInQueue={isCalledInQueue}
        isContainerOptionsSelected={isContainerOptionsSelected}
        isCalledClient={isCalledClient}
        selectedQueueCategory={selectedQueueCategory}
        selectedCarsQueue={selectedCarsQueue}
        isDataBaseResearchInProgress={isDataBaseResearchInProgress}
        isParkingSpacesAvailable={isParkingSpacesAvailable}
        setIsCalledClient={setIsCalledClient}
        setIsContainerOptionsSelected={setIsContainerOptionsSelected}
        setContainerName={setContainerName}
        setClientName={setClientName}
        updateCarStatus={updateCarStatus}
        redirectCarInQueueOut={redirectCarInQueueOut}
        addCarInQueue={addCarInQueue}
        setActiveCarStatus={setActiveCarStatus}
        setActiveCarId={setActiveCarId}
        setPrintTicketId={setPrintTicketId}
        printEntryTicket={printEntryTicket}
        setIsCalledInQueue={setIsCalledInQueue}
        setSelectionFromQueue={setSelectionFromQueue}
        synchronizeDataBase={synchronizeDataBase}
        isDisabledButtonLoading={isDisabledButtonLoading}
      />

      <OperatorQueue
        setSelectedCarsQueue={setSelectedCarsQueue}
        setIsSelectedQueueOut={setIsSelectedQueueOut}
        parkingSpaces={operatorQueueData?.parkingSpaces}
        queueData={operatorQueueData?.out?.items}
        queueName={'Машины на территории'}
        activeCarId={activeCarId}
        setActiveCarId={setActiveCarId}
        setActiveCarStatus={setActiveCarStatus}
        isSelectionFromQueue={isSelectionFromQueue}
        carsInQueue={carCountOut}
        isDisabledButtonLoading={isDisabledButtonLoading}
      />
      {isLoadingDataBaseSync && <ModalLoader text="обновление базы..." />}
      {isLoadingPrintEntryTicket && <ModalLoader text="подготовка к печати..." />}
      <ModalTicketInWorking isActive={isActiveModalInWorking} setActive={setActiveModalInWorking} />
    </div>
  );
};

export default Operator1Page;
