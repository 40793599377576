export function commonHeaders() {
  return {
    headers: {
      'Content-Type': 'application/json',
      'X-API-TOKEN': JSON.parse(localStorage.getItem('user')).token
    }
  };
}

export function multipartHeaders() {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-API-TOKEN': JSON.parse(localStorage.getItem('user')).token
    }
  };
}
