import React, { useEffect, useState } from 'react';
import { tehnoSkyTerminalLogo } from '../../../assets/icons';
import styles from './QueueHeader.module.scss';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const QueueHeader = () => {
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState<string>();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setTime(format(now, 'HH:mm'));
      setDate(format(now, 'd MMMM', { locale: ru }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <header className={styles.headerContainer}>
      <img className={styles.logo} src={tehnoSkyTerminalLogo} alt="logo" />
      <div className={styles.dateTimeContainer}>
        <div className={styles.date}>
          <span>{date}</span>
        </div>
        <div className={styles.time}>
          <span>{time}</span>
        </div>
      </div>
    </header>
  );
};

export default QueueHeader;
