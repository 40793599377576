import classes from './SectionContent.module.scss';
import { DropDown } from '../../../DropDown/DropDown';
import { crossIcon } from '../../../../../assets/icons/index';
import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { CustomTextField } from '../../../CustomTextField/CustomTextField';
import { SearchTextField } from '../../../SearchTextField/SearchTextField';
import { Button } from '../../../Button/Button';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import CustomRadio from '../../../CustomRadio/CustomRadio';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  rulesByLang,
  rulesEnLang,
  rulesRuLang
} from '../../../../../schemas/ValidationSchemas/ModalSectionSchema';
import { useGetCategoriesTopicsForModal } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useGetCategoriesTopicsForModal';
import { useCreateCategory } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useCreateCategory';
import { useEditCategory } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useEditCategory';
import {
  categoryServiceItemInModal,
  categoryServiceType,
  ModalContentProps,
  Topic
} from '../../../../../types/Admin/CategoriesTopicsTypes';
import { useGetAllCategoriesTopics } from '../../../../../hooks/Admin/CategoriesAndServisesTable/useGetAllCategoriesTopics';
import { ModalMode, SectionOrCategory } from '../../../../../types/enums';

const topLevel = { id: null, nameRu: 'Верхний уровень' };
export const SectionContent: FC<ModalContentProps> = ({
  isActive,
  selectedRadioValue,
  handleRadioChange,
  setActiveModal,
  modalDataFromTable,
  modalMode
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [startTime, setStartTime] = useState('10:00');
  const [endTime, setEndTime] = useState('18:00');

  const { createCategory, isLoadingCreatedCategory } = useCreateCategory(() =>
    setActiveModal(false)
  );
  const { editCategory, isLoadingCategoryEditing } = useEditCategory(
    setActiveModal,
    modalDataFromTable.id
  );
  const { allCategoriesTopicsResp, isAllCategoriesTopicsReceived } = useGetAllCategoriesTopics();
  const [addedTopics, setAddedTopics] = useState<categoryServiceType[]>([]);
  const [allTopics, setAllTopics] = useState<categoryServiceType[]>([]);
  const [startParentCategoryItems, setParentCategoryItems] = useState<categoryServiceType[]>([
    topLevel
  ]);
  const [currentParentCategoryItems, setCurrentParentCategoryItems] = useState<
    categoryServiceType[]
  >([]);

  const [dropDownItem, setDropDownItem] = useState<categoryServiceType>(topLevel);
  const { categoriesAndTopics, isAllCategoriesAndTopicsReceived } = useGetCategoriesTopicsForModal(
    topLevel,
    setParentCategoryItems
  );

  function checkIsNameUnique(nameField: string, value: string): boolean {
    if (isAllCategoriesTopicsReceived && modalMode === ModalMode.Create) {
      return !allCategoriesTopicsResp?.sections.some((obj) => obj[nameField] === value);
    }
    return true;
  }

  const ModalSectionSchema = yup.object({
    inputTextRu: yup
      .string()
      .required('Необходимо заполнить поле')
      .test('unique-nameRu', 'Такое наименование уже существует', (value) =>
        checkIsNameUnique('nameRu', value)
      )
      .test('no-empty-spaces', 'Введите текст', (value) => {
        return !/^\s+$/.test(value);
      })
      .matches(rulesRuLang, { message: 'Введите текст на русском языке', excludeEmptyString: true })
      .max(43, 'Превышен лимит символов'),
    inputTextEn: yup
      .string()
      .required('Необходимо заполнить поле')
      .test('unique-nameEn', 'Такое наименование уже существует', (value) =>
        checkIsNameUnique('nameEn', value)
      )
      .test('no-empty-spaces', 'Введите текст', (value) => {
        return !/^\s+$/.test(value);
      })
      .matches(rulesEnLang, {
        message: 'Введите текст на английском языке',
        excludeEmptyString: true
      })
      .max(43, 'Превышен лимит символов'),
    inputTextBy: yup
      .string()
      .required('Необходимо заполнить поле')
      .test('unique-nameBe', 'Такое наименование уже существует', (value) =>
        checkIsNameUnique('nameBe', value)
      )
      .test('no-empty-spaces', 'Введите текст', (value) => {
        return !/^\s+$/.test(value);
      })
      .matches(rulesByLang, {
        message: 'Введите текст на белорусском языке',
        excludeEmptyString: true
      })
      .max(43, 'Превышен лимит символов'),
    searchText: yup.string()
  });

  type FormData = yup.InferType<typeof ModalSectionSchema>;
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(ModalSectionSchema),
    mode: 'onChange',
    defaultValues: {
      inputTextRu: '',
      inputTextEn: '',
      inputTextBy: '',
      searchText: ''
    }
  });

  useEffect(() => {
    if (!isActive) {
      setMenuOpen(false);
    }
  }, [isActive]);

  useEffect(() => {
    if (modalDataFromTable && modalDataFromTable?.topics && modalDataFromTable.topics?.length > 0) {
      setAddedTopics(modalDataFromTable.topics);
    }

    if (isAllCategoriesAndTopicsReceived) {
      const updatedTopics = categoriesAndTopics?.topics.filter(
        (topic: categoryServiceType) => !addedTopics.some((service) => service.id === topic.id)
      );
      addedTopics?.length > 0 && modalMode === ModalMode.Edit
        ? setAllTopics(updatedTopics)
        : setAllTopics(categoriesAndTopics?.topics);
    }

    if (modalDataFromTable && !modalDataFromTable.isParentCategory) {
      setDropDownItem(modalDataFromTable.parentCategory);
    } else {
      setDropDownItem(topLevel);
    }
  }, [modalDataFromTable, isAllCategoriesAndTopicsReceived]);

  useEffect(() => {
    if (startParentCategoryItems) {
      const filteredItems = filteredParentCategoryItems(startParentCategoryItems);
      if (modalMode === ModalMode.Edit) {
        setCurrentParentCategoryItems(filteredItems);
      } else {
        setCurrentParentCategoryItems(startParentCategoryItems);
      }
    }
  }, [modalDataFromTable]);

  function filteredParentCategoryItems(
    categoriesArr: categoryServiceType[]
  ): categoryServiceType[] {
    if (modalDataFromTable) {
      return categoriesArr.filter(
        (category: categoryServiceType) => category.id !== modalDataFromTable.id
      );
    }
    return categoriesArr;
  }

  const filterServices = (searchValue: string): void => {
    const filteredServices: categoryServiceItemInModal[] = categoriesAndTopics.topics?.filter(
      (service: Topic) => {
        return service.nameRu.toLowerCase().includes(searchValue.toLowerCase());
      }
    );
    setAllTopics(filteredServices);
  };

  useEffect(() => {
    if (modalDataFromTable && modalDataFromTable.startFinishWork) {
      const timeArr = modalDataFromTable.startFinishWork.split('-');
      setStartTime(timeArr[0]);
      setEndTime(timeArr[1]);
      setValue('inputTextRu', modalDataFromTable.nameRu);
      setValue('inputTextEn', modalDataFromTable.nameEn);
      setValue('inputTextBy', modalDataFromTable.nameBe);
    } else {
      handleResetForm();
    }
  }, [modalDataFromTable]);

  function handleResetForm() {
    reset();
    setStartTime('10:00');
    setEndTime('18:00');
    setAddedTopics([]);
    setMenuOpen(false);
  }

  function addServiceToArr(item: categoryServiceType): void {
    if (!addedTopics.some((service: categoryServiceType): boolean => service.id === item.id)) {
      setAddedTopics((prevState: categoryServiceType[]) => [...prevState, item]);
    }
  }

  function addRemovedServiceToArr(item: categoryServiceType): void {
    if (!allTopics.some((service: categoryServiceType): boolean => service.id === item.id)) {
      setAllTopics((prevState: categoryServiceType[]) => [...prevState, item]);
    }
  }

  function removeServiceById(id: number): void {
    setAddedTopics((prevState: categoryServiceType[]) =>
      prevState.filter((service: categoryServiceType) => service.id !== id)
    );
  }

  function removeSelectedServiceById(id: number): void {
    setAllTopics((prevState: categoryServiceType[]) =>
      prevState.filter((service: categoryServiceType) => service.id !== id)
    );
  }

  const onSubmit: SubmitHandler<FormData> = async (formData: FormData) => {
    const { inputTextRu, inputTextEn, inputTextBy } = formData;
    const categoryData = {
      nameRu: inputTextRu,
      nameBe: inputTextBy,
      nameEn: inputTextEn,
      isParentCategory: dropDownItem && dropDownItem?.id ? false : true,
      ...(dropDownItem && dropDownItem?.id && { parentCategoryId: dropDownItem?.id }),
      topics: addedTopics.map((service: categoryServiceType) => service.id),
      startWork: startTime,
      endWork: endTime
    };
    if (startTime && endTime) {
      if (modalMode === ModalMode.Edit) {
        if (editCategory) {
          editCategory(categoryData);
        }
      } else {
        if (createCategory) {
          createCategory(categoryData);
        }
      }
    }
  };

  return (
    <form className={classes.sectionForm}>
      <h2>Раздел/ услуга</h2>
      <div className={classes.sectionContent}>
        {(isLoadingCreatedCategory || isLoadingCategoryEditing) && (
          <div className={classes.load}>
            <div className={classes.loadContent}>
              <p>Cохранение...</p>
            </div>
          </div>
        )}
        <div>
          <div className={classes.radioContainer}>
            <div className={classes.radio}>
              <CustomRadio
                id={'section'}
                value={'section'}
                checked={selectedRadioValue === 'section'}
                onChange={handleRadioChange}
                label={'Раздел'}
                disabled={
                  modalMode === ModalMode.Edit &&
                  modalDataFromTable.radioType === SectionOrCategory.Service
                }
              />
            </div>
            <div className={classes.radio}>
              <CustomRadio
                id={'service'}
                value={'service'}
                checked={selectedRadioValue === 'service'}
                onChange={handleRadioChange}
                label={'Услуга'}
                disabled={
                  modalMode === ModalMode.Edit &&
                  modalDataFromTable.radioType === SectionOrCategory.Section
                }
              />
            </div>
          </div>
          <div className={classes.nameRu}>
            <CustomTextField
              {...register('inputTextRu')}
              name="inputTextRu"
              value={watch('inputTextRu')}
              labelName={'Наименование RU'}
              errorMessage={errors.inputTextRu?.message}
            />
          </div>
          <div className={classes.nameEn}>
            <CustomTextField
              {...register('inputTextEn')}
              name="inputTextEn"
              value={watch('inputTextEn')}
              labelName={'Наименование EN'}
              errorMessage={errors.inputTextEn?.message}
            />
          </div>
          <div className={classes.searchContainer}>
            <SearchTextField
              {...register('searchText')}
              name="searchText"
              labelName={'Добавить услуги'}
              placeholder={'Введите название'}
              clearInput={(): void => {
                setValue('searchText', '');
                filterServices('');
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => filterServices(e.target.value)}>
              {allTopics?.map((item) => (
                <div
                  key={item.id}
                  className={classes.serviceItem}
                  onClick={() => {
                    addServiceToArr(item);
                    removeSelectedServiceById(item.id);
                  }}>
                  <span>{item.nameRu}</span>
                </div>
              ))}
            </SearchTextField>
          </div>
        </div>
        <div className={classes.rightPart}>
          <div className={classes.nameBe}>
            <CustomTextField
              {...register('inputTextBy')}
              name="inputTextBy"
              value={watch('inputTextBy')}
              labelName={'Наименование BY'}
              errorMessage={errors.inputTextBy?.message}
            />
          </div>
          <div className={classes.chaptersDropdown}>
            <DropDown
              isMenuOpen={isMenuOpen}
              setMenuOpen={setMenuOpen}
              label={'Родительский раздел'}
              activeService={dropDownItem?.nameRu || topLevel.nameRu}>
              {currentParentCategoryItems.map((item) => (
                <li
                  key={item.nameRu}
                  className={classes.serviceItem}
                  onClick={(): void => {
                    setDropDownItem(item);
                    setMenuOpen(false);
                  }}>
                  <span>{item.nameRu}</span>
                </li>
              ))}
            </DropDown>
          </div>
          <div className={classes.addedServices}>
            <CustomTextField labelName={'Добавленные услуги'} disabled={true}>
              {addedTopics.map((item) => (
                <div
                  key={item.id}
                  className={classes.addedServiceItem}
                  onClick={() => {
                    removeServiceById(item.id);
                    addRemovedServiceToArr(item);
                  }}>
                  <div className={classes.crossIcon}>
                    <img src={crossIcon} alt={'cross'} />
                  </div>
                  <span>{item.nameRu}</span>
                </div>
              ))}
            </CustomTextField>
          </div>
          <div className={classes.buttonsContainer}>
            <Button content={'отмена'} btnStyle={'white'} onClick={() => setActiveModal(false)} />
            <Button
              content={'сохранить'}
              btnStyle={'blue'}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoadingCreatedCategory || isLoadingCategoryEditing}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
