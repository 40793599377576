import { useQuery } from 'react-query';
import axios from 'axios';
import { commonHeaders } from '../../../constants/HeadersAPI';
import { AdminAPI } from '../../../constants/API';
import { modifyParkingPlacesData } from '../../../utils/Admin/ParkingPlacesTable/modifyParkingPlacesData';

export const useGetAllParkingPlaces = () => {
  const {
    data: parkingPlacesResp,
    isFetching: isLoadingParkingPlaces,
    isSuccess: isParkingPlacesRecieved
  } = useQuery(['parkingPlaces'], () => axios.get(AdminAPI.getAllParkingPlaces, commonHeaders()), {
    select: ({ data }) => modifyParkingPlacesData(data)
  });

  return { parkingPlacesResp, isLoadingParkingPlaces, isParkingPlacesRecieved };
};
