import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';
import { AnalyticsAndReportsDateType } from '../../../../types/Admin/AnalyticsAndReports';

type CarNumberReportPeriodType = {
  vehicleNumber: string;
  dateIn: string;
  itemType: string;
  client: string;
  timeIn: string;
  timeInZone: string;
  timeOut: string;
  isImport: boolean;
  isExport: boolean;
};

export const useGetCarNumberReportPeriod = (date: AnalyticsAndReportsDateType) => {
  const {
    data: carNumberReportPeriodResp,
    isFetching: isLoadingCarNumberReportPeriod,
    isSuccess: isCarNumberReportPeriodRecieved,
    refetch: refetchCarNumberReport
  } = useQuery(
    ['carNumberReportPeriod'],
    () =>
      axios.get<CarNumberReportPeriodType[]>(
        `${AdminAnalyticsAndReportsAPI.getCarNumbersReport}?startDate=${date.startDate}&endDate=${date.endDate}`,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e, idx) => {
            return {
              num: idx + 1,
              carNumber: e.vehicleNumber,
              date: e.dateIn,
              category: e.itemType,
              company: e.client,
              arrivalTime: e.timeIn,
              insideTerritoryTime: e.timeInZone,
              departureTime: e.timeOut,
              cargoImport: e.isImport,
              cargoExport: e.isExport
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    carNumberReportPeriodResp,
    isLoadingCarNumberReportPeriod,
    isCarNumberReportPeriodRecieved,
    refetchCarNumberReport
  };
};
