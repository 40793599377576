import { useQuery } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { ticketDataType } from '../../pagesMain/Operator1Page/Operator1Page';

export const useGetCarData = (
  activeCarId: number | null,
  setPrintTicketId?: (id: number | null) => void
) => {
  const {
    data: carDataResp,
    isLoading: isLoadingCarData,
    isSuccess: isCarDataRecieved
  } = useQuery(
    ['activeCarData', activeCarId],
    () => axios.get<ticketDataType>(OperatorsAPI.getCarData(activeCarId), commonHeaders()),
    {
      onSuccess: (resp) => {
        if (setPrintTicketId) {
          setPrintTicketId(resp?.data.entryTicket?.entryTicketId);
        }
      },
      enabled: !!activeCarId
    }
  );

  return { carDataResp, isLoadingCarData, isCarDataRecieved };
};
