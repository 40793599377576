import PoorVisionMode from '../../components/Main/TerminalModes/PoorVision/PoorVisionMode/PoorVisionMode';
import React, { useState } from 'react';
import NormalMode from '../../components/Main/TerminalModes/Normal/NormalMode/NormalMode';
import { useSearchParams } from 'react-router-dom';
import { useGetOrderUnits } from '../../hooks/TerminalServices/useGetOrderUnits';

export enum TerminalMode {
  Normal = 'normal',
  NotNormal = 'notNormal'
}

const TickerText = '';

const TerminalPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [mode, setMode] = useState<TerminalMode>(TerminalMode.Normal);
  const { orderUnitsData } = useGetOrderUnits(token);

  return (
    <>
      {mode === TerminalMode.Normal && orderUnitsData && (
        <NormalMode setMode={setMode} orderUnitsData={orderUnitsData} tickerText={TickerText} />
      )}
      {mode === TerminalMode.NotNormal && orderUnitsData && (
        <PoorVisionMode setMode={setMode} orderUnitsData={orderUnitsData} />
      )}
    </>
  );
};

export default TerminalPage;
