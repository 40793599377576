import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';
import { AnalyticsAndReportsDateType } from '../../../../types/Admin/AnalyticsAndReports';

type StationReportPeriodType = {
  itemType: string;
  importCargo: number;
  exportCargo: number;
  avgTimeInZone: string;
  avgTimeInQueue: string;
  transportNumber: number;
};

export const useGetStationReportPeriod = (date: AnalyticsAndReportsDateType) => {
  const {
    data: stationReportPeriodResp,
    isFetching: isLoadingStationReportPeriod,
    isSuccess: isStationReportPeriodRecieved,
    refetch: refetchStationReportPeriod
  } = useQuery(
    ['stationReportPeriod'],
    () =>
      axios.get<StationReportPeriodType[]>(
        `${AdminAnalyticsAndReportsAPI.getStationReportPeriod}?startDate=${date.startDate}&endDate=${date.endDate}`,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e) => {
            return {
              identity: e.itemType,
              importOfCargo: e.importCargo,
              exportOfCargo: e.exportCargo,
              averageTimeOnTerritory: e.avgTimeInZone,
              averageTimeInQueue: e.avgTimeInQueue,
              vehicleCount: e.transportNumber
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    stationReportPeriodResp,
    isLoadingStationReportPeriod,
    isStationReportPeriodRecieved,
    refetchStationReportPeriod
  };
};
