import React, { FC } from 'react';
import classNames from 'classnames';

import classes from './TerminalModal.module.scss';

type TerminalModalType = {
  activeModal: boolean;
  setActiveModal: (value: boolean) => void;
};

const TerminalModal: FC<TerminalModalType> = ({ activeModal, setActiveModal }) => {
  const handleClickCancel = () => {
    setActiveModal(false);
  };
  return (
    <div className={classNames(classes.modal, { [classes.active]: activeModal })}>
      <div className={classes.modalContainer}>
        <button className={classes.btn} onClick={handleClickCancel}>
          x
        </button>
        <div className={classes.title}>идет печать...</div>
      </div>
    </div>
  );
};

export default TerminalModal;
