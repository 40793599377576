import React, { FC } from 'react';
import { ModalWorkplaceOptionType } from './ModalWorkplace';
import { lockIcon } from '../../../../assets/icons';
import classes from './ModalWorkplace.module.scss';

type Props = {
  setLockedTip: (tipId: number) => void;
  optionValue: ModalWorkplaceOptionType;
};

export const FormatOptionLabel: FC<Props> = ({ setLockedTip, optionValue }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: optionValue.locked ? 'gray' : 'black',
      cursor: optionValue.locked ? 'default' : 'pointer',
      position: 'relative',
      padding: '5px'
    }}>
    <span>{optionValue.label}</span>
    {optionValue.locked && (
      <img
        onMouseEnter={() => setLockedTip(optionValue.value)}
        onMouseLeave={() => setLockedTip(null)}
        className={classes.lockIcon}
        src={lockIcon}
        alt="lock-icon"
      />
    )}
  </div>
);
