import classNames from 'classnames';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import classes from './ModalEmployees.module.scss';
import CustomRadio from '../../../CustomRadio/CustomRadio';
import { CustomTextField } from '../../../CustomTextField/CustomTextField';
import { Button } from '../../../Button/Button';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalEmployeeSchema } from '../../../../../schemas/ValidationSchemas/ModalEmployeeSchema';
import { EmployerType } from '../../../../../pagesAdmin/EmployersPage/EmployersPage';
import { CreateNewUserType } from '../../../../../hooks/Admin/EmployeesTable/usePostNewEmployer';
import { UpdateUserDataType } from '../../../../../hooks/Admin/EmployeesTable/usePutUserData';

type ModalEmployeesProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
  data?: EmployerType;
  activeRowDataEmployer?: EmployerType;
  createNewEmployer?: (userData: CreateNewUserType) => void;
  updateUserData?: (userData: UpdateUserDataType) => void;
  modalMode: string;
  isLoadingCreate: boolean;
  isLoadingUpdate: boolean;
};

const RadioButtons = [
  {
    type: 'entryOperator',
    label: 'Оператор на въезд'
  },
  {
    type: 'exitOperator',
    label: 'Оператор на выезд'
  },
  {
    type: 'limitedAgent',
    label: 'Агент с ограниченными правами'
  },
  {
    type: 'unlimitedAgent',
    label: 'Агент с расширенными правами'
  },
  {
    type: 'admin',
    label: 'Администратор'
  }
];
export const ModalEmployees: FC<ModalEmployeesProps> = ({
  isActive,
  setActive,
  activeRowDataEmployer,
  modalMode,
  createNewEmployer,
  updateUserData,
  isLoadingCreate,
  isLoadingUpdate
}) => {
  const [selectedRadio, setSelectedRadio] = useState('Оператор на въезд');
  const modalRef = useRef<HTMLDivElement>(null);

  type FormData = yup.InferType<typeof ModalEmployeeSchema>;
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(ModalEmployeeSchema),
    mode: 'onChange',
    defaultValues: {
      employeeName: '',
      password: ''
    }
  });

  const handleClickOutside = (event: MouseEvent): void => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setActive(false);
    }
  };

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadio(e.target.value);
  };

  const handleEmployeeNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('employeeName', e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('password', e.target.value);
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const { employeeName, password } = data;
    if (modalMode === 'edit' && activeRowDataEmployer.id) {
      updateUserData({
        userId: activeRowDataEmployer.id,
        body: { password: password, fullName: employeeName, role: selectedRadio }
      });
    }
    if (modalMode === 'create') {
      createNewEmployer({ password: password, fullName: employeeName, role: selectedRadio });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (activeRowDataEmployer) {
      setValue('employeeName', activeRowDataEmployer.employee || '');
      setSelectedRadio(activeRowDataEmployer?.accessLevel);
    } else {
      setValue('employeeName', '');
      setValue('password', '');
    }
  }, [activeRowDataEmployer]);

  useEffect(() => {
    if (!isActive) {
      setValue('employeeName', '');
      setSelectedRadio('Оператор на въезд');
    }
  }, [isActive]);

  return (
    <div className={classNames(classes.modal, { [classes.active]: isActive })}>
      <div className={classes.modalContainer} ref={modalRef}>
        <form className={classes.employeesForm}>
          {(isLoadingCreate || isLoadingUpdate) && (
            <div className={classes.load}>
              <div className={classes.loadContent}>
                <p>Cохранение...</p>
              </div>
            </div>
          )}
          <h2>Сотрудник</h2>
          <div className={classes.radioContainer}>
            {RadioButtons.map((item, key) => (
              <div key={key} className={classes.radio}>
                <CustomRadio
                  id={item.type}
                  value={item.label}
                  checked={selectedRadio === item.label}
                  onChange={handleRadioChange}
                  label={item.label}
                />
              </div>
            ))}
          </div>
          <div className={classes.workpaceName}>
            <CustomTextField
              {...register('employeeName')}
              name="employeeName"
              value={watch('employeeName')}
              onChange={handleEmployeeNameChange}
              labelName={'Фамилия, инициалы сотрудника (логин)'}
              errorMessage={errors.employeeName?.message}
            />
          </div>
          <div className={classes.passwordContainer}>
            <div className={classes.windowNum}>
              <CustomTextField
                {...register('password')}
                name="password"
                value={watch('password')}
                onChange={handlePasswordChange}
                labelName={'Пароль'}
                errorMessage={errors.password?.message}
              />
            </div>
          </div>
          <div className={classes.buttonsContainer}>
            <Button content={'отмена'} btnStyle={'white'} onClick={() => setActive(false)} />
            <Button
              content={'сохранить'}
              btnStyle={'blue'}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoadingCreate || isLoadingUpdate}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
