// eslint-disable-next-line @typescript-eslint/no-unused-vars
type formatCategoryType = {
  searchCategory: '' | 'С' | 'К' | 'Т';
  listOfCars: string[];
};

const filterCategory = (searchCategory, listOfCars) => {
  if (!searchCategory) {
    return listOfCars;
  }
  return listOfCars.filter(({ categoryAuto }) => {
    return categoryAuto === searchCategory;
  });
};

export default filterCategory;
